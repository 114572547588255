<template>
  <v-layout justify-center align-center>
    <v-flex shrink style="text-align:center">
      <v-sheet class="d-flex align-baseline mb-6 bg-surface-variant"
      max-height="80vh">
      <v-img
        src="@/assets/home.png"
        alt="ROM Technik Earns The Energy Efficiency Toolbox"
      />
      </v-sheet>

      <v-btn 
        v-if="isLoggedIn()"
        class="mb-2 mr-10"
        @click="showProjects"
        color="primary"
      >
        <v-icon left>mdi-view-list</v-icon>
        Projekte
      </v-btn>

      <v-btn
        v-if="isLoggedIn()"
        class="mb-2 "
        @click="createProject"
        color="primary"
      >
        <v-icon left>mdi-database-plus</v-icon>
        Neues Projekt
      </v-btn>
      <v-btn class="mb-2 ml-10" @click="showAbout" color="primary">
          <v-icon left>mdi-information</v-icon>
          Über EARNS
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {};
  },

  methods: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    showProjects() {
      this.$router.push({ name: "ProjectList", params: {} });
    },

    createProject() {
      this.$router.push({ name: "NewProject", params: {} });
    },
     showAbout() {
        this.$router.push({name: "About", params: {}})
    },
  }
};
</script>
