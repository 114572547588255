<template>
  <tbody>
  <tr v-for="(item, index) in fuels" :key="item.name">
    <td>{{ item.name }}</td>
    <td>0</td>
    <td>
      <v-text-field label="" v-model="data[index]" placeholder="0" :error-messages="validationErrors[index]"
                    outlined dense class="mt-6"></v-text-field>
    </td>
  </tr>
  </tbody>
</template>

<script>
export default {
  name: "EnergyDataTableInputs",
  props: {
    value: {type: Array, required: true},
    fuels: {type: Array, required: true},
    validationErrors: {type: Array, required: true},
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(data) {
        this.$emit("input", data)
      }
    }
  }
}
</script>

<style scoped>

</style>