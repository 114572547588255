<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="amount"
      type="number"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.amount
        "
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="electricity"
      type="number"
      :error-messages="fieldErrors.electricity"
      label="Stromverbrauch"
      suffix="kWh/a"
      :hint="hint.electricity"
      :rules="validationErrors.electricity"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.electricity
        "
        @click="getReferenceParameter(`electricity`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="heat"
      type="number"
      :error-messages="fieldErrors.heat"
      label="Wärmeverbrauch"
      suffix="kWh/a"
      :hint="hint.heat"
      :rules="validationErrors.heat"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.heat
        "
        @click="getReferenceParameter(`heat`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="cold"
      type="number"
      :error-messages="fieldErrors.cold"
      label="Kälteverbrauch"
      suffix="kWh/a"
      :hint="hint.cold"
      :rules="validationErrors.cold"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.cold
        "
        @click="getReferenceParameter(`cold`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";

export default {
  name: "FreeConsumerForm",
  extends: BaseForm,

  data() {
    return {
      amount: null,
      electricity: null,
      heat: null,
      cold: null,

      fields: {
        amount: "amount",
        electricity: "electricity",
        heat: "heat",
        cold: "cold"
      },
    };
  },
};
</script>

<style scoped></style>
