<template>
  <v-form @submit.prevent="" ref="form">
    <v-checkbox
      v-model="fanCalculation"
      :error-messages="fieldErrors.fanCalculation"
      label="Ventilatoren separat berechnen"
    >
      <v-tooltip v-if="hint.external_fan_calculation" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.external_fan_calculation }}</span>
      </v-tooltip>
    </v-checkbox>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";

export default {
  name: "RltFormShared",
  extends: BaseForm,

  data() {
    return {
      fanCalculation: false,
      fields: {
        fanCalculation: "external_fan_calculation",
      },
    };
  },

  mounted() {
    if (!this.fanCalculation) {
      this.fanCalculation = false;
    }
  },
};
</script>

<style scoped></style>
