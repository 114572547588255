import "@mdi/font/css/materialdesignicons.css" // Ensure you are using css-loader
import Vue from "vue";
import Vuetify, { VSnackbar } from "vuetify/lib";
import VuetifyToast from "vuetify-toast-snackbar";

import Ripple from "vuetify/lib/directives/ripple";

import de from "vuetify/es5/locale/de";


const vuetifyObj = new Vuetify({
    icons: {
        iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
                primary: "#003463", // navy blue
                secondary: "#618197", // darken grey
                accent: "#0099dc", // blue
                tertiary: "#d8dfe3", // light grey
                quaternary: "#e8ebee", // darken white
            },
        },
    },

    lang: {
        locales: { de },
        current: "de"
    }
});

Vue.use(VuetifyToast, { $vuetify: vuetifyObj.framework, queueable: true });

Vue.use(Vuetify, {
    components: {
        VSnackbar,
    },
    directives: {
        Ripple,
    },
});


export default vuetifyObj;


