<template>
  <v-form @submit.prevent="" ref="form">
    <v-checkbox
      v-model="useManualUptime"
      :error-messages="fieldErrors.useManualUptime"
      label="Manuelle Brenndauer"
      :disabled="!sharedData.zone"
    >
      <v-tooltip v-if="hint.use_manual_uptime !== null" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.use_manual_uptime }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.use_manual_uptime"
        @click="getReferenceParameter(`use_manual_uptime`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-text-field
      v-model.number="uptime"
      type="number"
      :error-messages="fieldErrors.uptime"
      label="Brenndauer"
      suffix="h/a"
      :disabled="!sharedData.zone || !useManualUptime"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="lampOrLight"
      :error-messages="fieldErrors.lampOrLight"
      label="Leuchte"
      :items="lampsOrLights"
      :disabled="!sharedData.change"
      :rules="validationErrors.lamp_or_light_qry"
      validate-on-blur
    >
      <v-tooltip
        v-if="hint.lamp_or_light_qry !== null"
        slot="append-outer"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.lamp_or_light_qry }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.lamp_or_light_qry"
        @click="getReferenceParameter(`lamp_or_light_qry`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-model.number="amount"
      type="number"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :disabled="!lampOrLight"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.amount"
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="power"
      :error-messages="fieldErrors.power"
      label="Leistung je Lampe"
      :items="powers"
      suffix="W"
      :disabled="!amount"
      :rules="validationErrors.power_qry"
      validate-on-blur
    >
      <v-tooltip v-if="hint.power_qry !== null" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.power_qry }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.power_qry"
        @click="getReferenceParameter(`power_qry`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="lightPerLamp"
      :error-messages="fieldErrors.lightPerLamp"
      label="Lampen je Leuchte"
      :items="lightPerLamps"
      :disabled="!power"
      :rules="validationErrors.light_per_lamp"
      validate-on-blur
    >
      <v-tooltip v-if="hint.light_per_lamp !== null" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.light_per_lamp }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.light_per_lamp"
        @click="getReferenceParameter(`light_per_lamp`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="ballast"
      :error-messages="fieldErrors.ballast"
      label="Vorschaltgerät"
      :items="ballasts"
      item-text="name"
      item-value="id"
      :disabled="!power"
      :rules="validationErrors.ballast"
      validate-on-blur
    >
      <v-tooltip v-if="hint.ballast !== null" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.ballast }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.ballast"
        @click="getReferenceParameter(`ballast`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="ballastPerLamp"
      :error-messages="fieldErrors.ballastPerLamp"
      label="Vorschaltgeräte je Leuchte"
      :items="ballastPerLamps"
      :disabled="!ballast"
      :rules="validationErrors.ballast_per_lamp"
      validate-on-blur
    >
      <v-tooltip
        v-if="hint.ballast_per_lamp !== null"
        slot="append-outer"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.ballast_per_lamp }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.ballast_per_lamp"
        @click="getReferenceParameter(`ballast_per_lamp`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="control"
      :error-messages="fieldErrors.control"
      label="Regelung"
      :items="controls"
      item-text="name"
      item-value="id"
      :disabled="!ballastPerLamp"
      :rules="validationErrors.control"
      validate-on-blur
    >
      <v-tooltip v-if="hint.control !== null" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.control }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.control"
        @click="getReferenceParameter(`control`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { cacheAdapter } from "@/cache";

export default {
  name: "Lighting2Form",
  extends: BaseForm,

  data() {
    return {
      useManualUptime: false,
      uptime: null,
      lampOrLight: null,
      amount: null,
      power: null,
      lightPerLamp: null,
      ballast: null,
      ballastPerLamp: null,
      control: null,

      fields: {
        useManualUptime: "use_manual_uptime",
        uptime: "uptime",
        lampOrLight: "lamp_or_light_qry",
        amount: "amount",
        power: "power_qry",
        lightPerLamp: "light_per_lamp",
        ballast: "ballast",
        ballastPerLamp: "ballast_per_lamp",
        control: "control"
      },

      zones: [],
      lampsOrLights: [],
      powers: [],
      lightPerLamps: [],
      ballasts: [],
      ballastPerLamps: [],
      controls: []
    };
  },

  computed: {
    zone() {
      return this.sharedData.zone;
    },

    change() {
      return this.sharedData.change;
    }
  },

  watch: {
    zone: {
      handler() {
        for (let value of this.zones.values()) {
          if (this.sharedData.zone === value.id) {
            this.uptime = value["norm_uptime"];
          }
        }
        this.useManualUptime = !!this.useManualUptime;
      },
      immediate: true
    },

    lampOrLight: {
      handler: "fetchPowers",
      immediate: true
    },

    change: {
      handler() {
        this.lampsOrLights = [];
        this.fetchLampOrLights();
      },
      immediate: true
    },

    power: {
      handler: "fetchBallasts",
      immediate: true
    },

    lightPerLamp: {
      handler() {
        this.ballastPerLamps = [];
        this.setBallastPerLampsOptions();
      },
      immediate: true
    }
  },

  beforeMount() {
    this.fetchBuildingTypes();
    this.fetchControls();
  },

  mounted() {
    if (!this.useManualUptime) {
      this.useManualUptime = false;
    }
  },

  methods: {
    fetchBuildingTypes() {
      this.$http
        .get("/api/v1/methods/lighting2/zone/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.zones = response.data;
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    fetchLampOrLights() {
      if (!this.sharedData.zone) return;

      this.$http
        .get("/api/v1/methods/lighting2/choices/", {
          params: {
            reference: this.reference,
            zone: this.sharedData.zone,
            change: this.change
          },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.lampsOrLights =
            this.change === "bulb"
              ? response.data["light"]
              : response.data["lamp"];
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    fetchPowers() {
      if (!this.sharedData.zone || !this.sharedData.change || !this.lampOrLight)
        return;

      this.$http
        .get("/api/v1/methods/lighting2/choices/", {
          params: {
            reference: this.reference,
            zone: this.sharedData.zone,
            change: this.sharedData.change,
            lamp_or_light: this.lampOrLight
          },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.powers = response.data["power"].map((x) => x.toString());

          if (this.powers.length === 1) {
            this.power = this.powers[0];
          }
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    fetchBallasts() {
      if (
        !this.sharedData.zone ||
        !this.sharedData.change ||
        !this.lampOrLight ||
        !this.power
      )
        return;

      this.$http
        .get("/api/v1/methods/lighting2/choices/", {
          params: {
            reference: this.reference,
            zone: this.sharedData.zone,
            change: this.sharedData.change,
            lamp_or_light: this.lampOrLight,
            power: this.power
          },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.lightPerLamps = response.data["amount"];

          if (this.lightPerLamps.length === 1) {
            this.lightPerLamp = this.lightPerLamps[0];
          }

          this.fetchBallastDetails(response.data["ballast"]);
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    fetchBallastDetails(ballastIds = []) {
      this.$http
        .get("/api/v1/methods/lighting2/ballast/", {
          params: {},
          adapter: cacheAdapter
        })
        .then((response) => {
          for (let value of response.data.values()) {
            if (ballastIds.includes(value["id"])) {
              this.ballasts.push(value);
            }
          }

          if (this.ballasts.length === 1) {
            this.ballast = this.ballasts[0].id;
          }
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    setBallastPerLampsOptions() {
      for (let i = 1; i <= this.lightPerLamp; i++) {
        this.ballastPerLamps.push(i);
      }

      if (this.ballastPerLamps.length === 1) {
        this.ballastPerLamp = this.ballastPerLamps[0];
      }
    },

    fetchControls() {
      this.$http
        .get("/api/v1/methods/lighting2/controls/", {
          params: {},
          adapter: cacheAdapter
        })
        .then((response) => {
          this.controls = response.data;

          if (this.controls.length === 1) {
            this.control = this.controls[0].id;
          }
        })
        .catch(() => {
          // TODO: Error handling
        });
    }
  }
};
</script>

<style scoped></style>
