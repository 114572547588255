<template>
  <v-form @submit.prevent="" ref="form">
    <v-checkbox
      v-model="useManualUptime"
      :error-messages="fieldErrors.useManualUptime"
      label="Manuelle Brenndauer"
      :disabled="!sharedData.building_type"
    >
      <v-tooltip v-if="hint.use_manual_uptime" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.use_manual_uptime }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.use_manual_uptime"
        @click="getReferenceParameter(`use_manual_uptime`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-text-field
      v-model.number="uptime"
      type="number"
      :error-messages="fieldErrors.uptime"
      label="Brenndauer"
      suffix="h/a"
      :disabled="!sharedData.building_type || !useManualUptime"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-if="reference"
      :disabled="!uptime || !sharedData.bgf"
      v-model="age"
      :error-messages="fieldErrors.age"
      label="Alter der Lichtanlage"
      :items="ages"
      item-text="age"
      item-value="id"
      :rules="validationErrors.age"
      validate-on-blur
    >
      <v-tooltip v-if="hint.age" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.age }}</span>
      </v-tooltip>
    </v-autocomplete>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";

export default {
  name: "Lighting1Form",
  extends: BaseForm,

  data() {
    return {
      useManualUptime: false,
      uptime: null,
      age: null,

      fields: {
        useManualUptime: "use_manual_uptime",
        uptime: "uptime",
        age: "age"
      },

      buildingTypes: [],
      ages: []
    };
  },

  computed: {
    buildingType() {
      return this.sharedData.building_type;
    }
  },

  watch: {
    buildingType: {
      handler() {
        for (let value of this.buildingTypes.values()) {
          if (this.sharedData.building_type === value.id) {
            this.uptime = value["norm_uptime"];
          }
        }
        this.useManualUptime = !!this.useManualUptime;
      },
      immediate: true
    }
  },

  beforeMount() {
    this.fetchBuildingTypes();
    this.fetchAges();
  },

  mounted() {
    if (!this.useManualUptime) {
      this.useManualUptime = false;
    }
  },

  methods: {
    fetchBuildingTypes() {
      this.$http
        .get("/api/v1/methods/lighting1/building_type/", {
          params: {
            planned: this.planned || null,
            reference: this.reference || null
          }
        })
        .then((response) => {
          this.buildingTypes = response.data;
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    fetchAges() {
      this.$http
        .get("/api/v1/methods/lighting1/age/", {
          params: {
            planned: this.planned || null,
            reference: this.reference || null
          }
        })
        .then((response) => {
          this.ages = response.data;

          // Set planned age to first ages entry. No user selection available.
          if (this.planned) {
            this.age = this.ages[0].id;
          }
        })
        .catch(() => {
          // TODO: Error handling
        });
    }
  }
};
</script>

<style scoped></style>
