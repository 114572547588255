<script>

export default {
  name: "BaseForm",
  props: {
    value: {type: Object, default: () => ({})},
    reference: {type: Boolean, default: false},
    planned: {type: Boolean, default: false},
    shared: {type: Boolean, default: false},
    validationErrors: {type: Object, default: () => ({})},
    otherData: {type: Object, default: () => ({})},
    sharedData: {type: Object, default: () => ({})},
    methodValue: {type: String, default: ""},

  },
  data() {
    return {
      touchedFields: [],
      newlyTouchedFields: [],
      hint: String,
    }
  },

  beforeMount() {
    this.fetchHints();
  },

  computed: {
    data: {
      get() {
        let data = {};
        for (let field in this.fields) {
          let fieldValue = this[field]

          data[this.fields[field]] = fieldValue

          if (fieldValue) {
            this.newlyTouchedFields = [...this.newlyTouchedFields, field]
          }
        }
        data.is_reference = this.reference

        if (!this.shared) {
          data = {...this.sharedData, ...data}
        }

        return data
      },
      set(data) {
        if (typeof data === "object") {
          let fields;
          let i = 0;
          while (fields !== this.fields) {
            if (i > 100) {
              console.error(
                  `[${this.$options.name}][BaseForm] Endless loop in fields suspected: ` +
                  "fields changed based on set values more than 100 times while setting data."
              );
            }
            i += 1
            fields = this.fields
            for (const [frontend, backend] of Object.entries(fields)) {
              let fieldValue = data[backend] ?? null
              this[frontend] = fieldValue
              if (fieldValue) {
                this.newlyTouchedFields = [...this.newlyTouchedFields, frontend]
              }
            }
          }

        }
      }
    },
    referenceData() {
      return this.reference ? this.data : this.otherData
    },
    plannedData() {
      return this.planned ? this.data : this.otherData
    },
    fieldErrors() {
      let errors = {};

      for (let field of this.touchedFields) {
        let fieldErrors = this.validationErrors[this.fields[field]]
        errors[field] = fieldErrors
      }
      return errors
    }
  },
  watch: {
    data: {
      handler(data, oldData) {
        if (JSON.stringify(data) !== JSON.stringify(oldData)) {
          this.$emit("input", data)
        }
      },
      immediate: true

    },
    value: {
      handler(value, oldValue) {
        if (value) {
          this.data = value

        }
      },
      immediate: true

    },
  },
  methods: {
    validateForm() {
      this.$refs.form.validate();
    },
    fetchHints() {
      this.$http
        .get(`/api/v1/methods/${this.methodValue}/help/`)
        .then((response) => {
          this.hint = Object.assign(
            {},
            ...response.data.map((x) => ({ [x.key]: x.help }))
          );
        })
        .catch(() => {
          this.hint = [];
        });
    },
    getReferenceParameter(parameter) {
      let strings = parameter.split("_");
      let string = "";
      strings.forEach(unify);

      function unify(value, index) {
        if (index != 0) {
          string += value.charAt(0).toUpperCase() + value.slice(1);
        } else {
          string += value;
        }
      }
      this[string] = this.referenceData[parameter];
    },
  },
}
</script>

<style scoped>

</style>