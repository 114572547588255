<template>
  <div>
    <h1>Freier Erzeuger</h1>
    <p>Die freie Erzeuger Maßnahmen dient dazu, beliebige Erzeugersysteme abzudecken. 
        Eine detailliertere Berechnung kann dabei außerhalb von EARNS durchgeführt werden und deren Ergebnisse hier eingetragen werden.</p>
    
    <h2>Beispiele</h2>
    <h3>Heizkessel</h3>
    <p>Ein vereinfachter Heizkessel kann mit folgenden Parametern eingegeben werden:</p>
    <table>
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Wert</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Primär erzeugte Energieform</td>
          <td>Wärme</td>
        </tr>
        <tr>
          <td>Primäre Aufwandszahl</td>
          <td>Nutzungsgrad des Kessels</td>
        </tr>
        <tr>
          <td>Primärer Energieträger</td>
          <td>z.B. Gas</td>
        </tr>
        <tr>
          <td>Passiv erzeugte Energieform</td>
          <td>keine</td>
        </tr>
        <tr>
          <td>Anteil elektrischer Hilfsenergie</td>
          <td>z.B. 0,02</td>
        </tr>
      </tbody>
    </table>

    <h3>BHKW</h3>
    <p>Ein vereinfachtes BHKW kann mit folgenden Parametern eingegeben werden:</p>
    <table>
      <thead>
        <tr>
          <th>Parameter</th>
          <th>Wert</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Primär erzeugte Energieform</td>
          <td>Wärme (für Wärme geführtes BHKW)</td>
        </tr>
        <tr>
          <td>Primäre Aufwandszahl</td>
          <td>thermischer Wirkungsgrad</td>
        </tr>
        <tr>
          <td>Primärer Energieträger</td>
          <td>z.B. Gas</td>
        </tr>
        <tr>
          <td>Passiv erzeugte Energieform</td>
          <td>Strom</td>
        </tr>
        <tr>
          <td>Passive Aufwandszahl</td>
          <td>elektrischer Wirkungsgrad</td>
        </tr>
        <tr>
          <td>Anteil elektrischer Hilfsenergie</td>
          <td>0</td>
        </tr>
      </tbody>
    </table>

    <parameter-help method='freegenerator' />
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "FreeGeneratorHelp",
  components: {
    ParameterHelp
  }
}
</script>

<style scoped>

</style>