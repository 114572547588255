<template>
  <div>
    <h1>Heizkessel</h1>
    <img src="@/assets/help/boiler/boiler.png" alt="Kessel">
    <p>Der Austausch eines alten bestehenden Heizkessels ist sehr wirtschaftlich und es besteht dadurch sogar die Möglichkeit den Brennstoff zu wechseln. Zum Teil ist der Austausch des bestehenden Heizkessels auch durch Verordnungen vorgegeben. Seit dem 01. Mai 2014 gilt die Energieeinsparverordnung 2014 (kurz: EnEV 2014), welche u.a. die Nachrüstpflicht für Heizkessel nach EnEV 2014 § 10 (Nachrüstung bei Anlagen und Gebäuden) erweitert:</p>
    <ul>
        <li>Heizkessel, die bis Ende des Jahres 1984 eingebaut oder aufgestellt wurden dürfen ab 2015 nicht mehr betrieben werden.</li>
        <li>Heizkessel, die 1985 oder später eingebaut oder aufgestellt wurden, darf man nach Ablauf von 30 Jahren nicht mehr betreiben.</li>
        <li>Die bereits bestehende Austauschpflicht für Heizungen, die vor dem 1. Oktober 1978 aufgestellt wurden, besteht weiterhin.</li>
    </ul>
    <p>Nach wie vor genießen folgende Heizungsanlagen einen Bestandsschutz und Eigentümer können sie weiter betreiben:</p>
    <ul>
        <li>Niedertemperatur-Heizkessel,</li>
        <li>Brennwert-Heizkessel,</li>
        <li>Heizungsanlagen mit einer Nennleistung unter vier Kilowatt (kW) oder über 400 kW,</li>
        <li>Heizkessel für marktunübliche flüssige oder gasförmige Brennstoffe,</li>
        <li>Anlagen, mit denen nur das warme Wasser bereitet wird,</li>
        <li>Küchenherde,</li>
        <li>Geräte, die hauptsächlich ausgelegt sind, um den Raum, in dem sie aufgestellt sind, zu beheizen, welche jedoch auch Warmwasser für die Zentralheizung und für sonstigen Gebrauch liefern.</li>
    </ul>

    <h3>Optimierung</h3>
    <p>Folgende Maßnahmen haben wesentlichen Einfluss auf die Effizienz eines Wärmeerzeugungs-Systems:</p>
    <table>
        <thead>
            <tr>
                <th>Massnahme</th>
                <th>Beschreibung</th>
                <th>EARNS</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Kesseltausch</td>
                <td>Austausch eines Kessels durch einen neuen Kessel</td>
                <td>möglich</td>
            </tr>
            <tr>
                <td>Brennstoffwechsel</td>
                <td>Verwendung eines anderen Brennstoffes</td>
                <td>möglich</td>
            </tr>
            <tr>
                <td>Überdimensionierung</td>
                <td>Anpassen der Kesselgröße an den tatsächlichen Bedarf</td>
                <td>möglich</td>
            </tr>
            <tr>
                <td>Temperaturnivea</td>
                <td>Reduzierung des Temperaturniveaus</td>
                <td>nur Erzeugerseitige</td>
            </tr>
            <tr>
                <td>Brennertausch</td>
                <td>Anpassen des Brenners an den tatsächlichen Bedarf, ggf. mit Brennstoffwechsel auf Erdgas</td>
                <td>momentan nicht möglich</td>
            </tr>
            <tr>
                <td>Regelung</td>
                <td>Optimierung der Regelfunktionen und der Kesselfolgeschaltung</td>
                <td>momentan nicht möglich</td>
            </tr>
        </tbody>
    </table>

    <p>Mit dem EARNS-Modul lässt sich der Tausch eines Kessels (mit ggf. erforderlichem Brennstoffwechsel) in einer Einkessel-Anlage berechnen. Dabei wird ein Heizsystem mit VL-/RL-temperaturen von 70/55°C zugrunde gelegt.
        Wird bei einem Kesseltausch ein anderer Brennstoff eingesetzt (z.B. Erdgas anstelle Heizöl, oder Holzpellets anstelle Erdgas), so wird neben der Änderung des Wirkungsgrades auch der durch den Brennstoffwechsel ggf. entstehende Kostenvorteil berücksichtigt.
    </p>
    <p>Für komplexere Erzeugersysteme sprechen Sie uns gerne an.</p>

    <hr>

    <h2>Kesseltypen</h2>

    <h3>Gas-Spezial-Heizkessel</h3>
    <p>Ein Gas-Spezial-Heizkessel ist ein meist bodenstehender atmosphärischer Kessel ohne Gebläseunterstützung, der speziell für die Nutzung mit Gas gebaut wurde. Die Verbrennungsluft wird durch die Sogwirkung des Gasstromes in die Brennkammer gezogen.</p>

    <h3>NT-Gas-Spezial-Heizkessel</h3>
    <p>Ein NT-Gas-Spezial-Heizkessel (Niedertemperatur-Gas-Spezial-Heizkessel) ist ein meist bodenstehender atmosphärischer Kessel ohne Gebläseunterstützung, der speziell für die Nutzung mit Gas gebaut wurde. Die Verbrennungsluft wird durch die Sogwirkung des Gasstromes in die Brennkammer gezogen. </p>

    <h3>NT-Gebläsekessel</h3>
    <p>Ein Gebläsekessel ist ein Heizkessel, der wahlweise mit einem Öl- oder Gasgebläsebrenner ausgerüstet werden kann. Beim Gebläsekessel wird die durch den Brenner freigesetzte Feuerungswärme des Brennstoffes an das Heizwasser im Heizkessel übertragen.</p>
    <p>Seit den 80er-Jahren sind „Niedertemperaturheizkessel“ (abgekürzt NT-Kessel) auf dem Markt. Das Besondere an diesen Kesseln ist, dass sie auch mit niedrigen Kesseltemperaturen betrieben werden können: Die Kesseltemperatur ändert sich (gleitende Betriebsweise) oder ist konstant niedrig.</p>
    <p>Nach § 2 Begriffsbestimmungen in der Energieeinsparverordnung (EnEV) „ist ein Niedertemperatur-Heizkessel ein Heizkessel, der kontinuierlich mit einer Eintrittstemperatur von 35 bis 40 Grad Celsius betrieben werden kann und in dem es unter bestimmten Umständen zur Kondensation des in den Rauchgasen enthaltenen Wasserdampfes kommen kann.“</p>

    <h3>Brennwertkessel</h3>
    <p>Ein Brennwertkessel ist ein Heizkessel, der für die Kondensation eines Großteils des in den Abgasen enthaltenen Wasserdampfes konstruiert ist. Brennwertkessel bieten heute die bestmögliche Weise der Öl- und Gasverbrennung für Heizzwecke. Wegen des besonders hohen energetischen Zusatznutzens bei Gas (bis zu 15 % gegenüber dem Niedertemperaturkessel) kann der Brennwertkessel in Verbindung mit diesem Brennstoff inzwischen bereits als Standard im Neubau gelten</p>

    <h3>Hackschnitzelkessel und Pelletkessel</h3>
    <p>Auf dem Boden stehender Festbrennstoff-Kessel, der durch Verbrennung von Pellets oder Hackschnitzel warmes Heizwasser und ggf. Warmwasser produziert. Holzpellets werden unter hohem Druck aus getrocknetem, naturbelassenem Restholz (Sägemehl, Hobelspäne, Waldrestholz) mit einem Durchmesser von ca. 6 - 8 mm und einer Länge von 5 - 45 mm gepresst. Holzhackschnitzel sind zerkleinertes Holz (Hackgut), zum Beispiel aus Waldholz, Sägerestholzen oder Holz aus der Ver- und Bearbeitung.</p>
    <h4>Prinzipieller Aufbau</h4>
    <img src="@/assets/help/boiler/pellet_aufbau.png" alt="Aufbau">
    <p>Pellet- und Hackschnitzelanlagen bestehen in der Regel aus den folgenden Komponenten:</p>
    <ul>
        <li>Feuerungsanlage</li>
        <li>Austragungssystem</li>
        <li>Brennstofflager</li>
        <li>Wärmeabgabesystem (ggf. mit Pufferspeicher)</li>
        <li>Abgasanlage</li>
        <li>Ascheaustragungssystem</li>
    </ul>
    <p>Im <u>Brennstofflager</u> werden die Holzpellets oder Hackschnitzel zwischengelagert und automatisch durch das Austragungssystem zur Feuerungsanlage transportiert. Das Lager wird in der Regel so ausgelegt, dass dort Brennmaterial für 3 Monate plus 14 Tage Reserve lagern kann. Die Größe hängt von der Leistung der Heizungsanlage ab. Das Lager sollte sich möglichst nah an dem Heizungsraum befinden.</p>
    <p>Das <u>Austragungssystem</u> wird über das Regelungssystem der Heizungsanlage gesteuert und führt der Feuerungsanlage bedarfsabhängig Brennstoff zu. Bei Hackschnitzelanlagen kommen in der Regel Förderschnecken zum Einsatz, bei Pelletanlagen sind es Förderschnecken oder Saugleitungen.</p>
    <p>In der <u>Feuerungsanlage</u> wird der Brennstoff verbrannt und die Nutzwärme über ein Wärmeabgabesystem abgeführt. Die Leistung wird übe die Brennstoff- und die Sauerstoffzufuhr geregelt. Es empfiehlt sich ein Pufferspeicher, um möglichst viele Vollastbetriebsstunden zu erreichen.</p>
    <p>An die Feuerungsanlage angeschlossen werden noch eine Abgasanlage, bestehend aus einem Schornstein und ggf. einer Rauchgasreinigungsstation, und ein Ascheaustragungssystem, welches häufig in Form einer Rüttelvorrichtung vorliegt und die Asche in einen unter der Feuerungsanlage befindenden Sammelbehälter entsorgt.</p>

    <h4>Wirkungsgrade</h4>
    <p>Moderne Hackschnitzelanlagen sind stufenlos bis ca. 30% der Volllast regelbar. Unter Volllast besitzen sie einen feuerungstechnischen Wirkungsgrad von 90 – 93%. Im Teillastbereich deutlich weniger. Teillasten unter 30% können nur durch Taktung der Anlage erreicht werden, wodurch der Kesselwirkungsgrad auf Grund von Anlaufverlusten sinkt. Dem entsprechend sind Kleinanlagen auf Grund ihrer höheren Kesselwirkungs- und Jahresnutzungsgrade Großanla-gen gegenüber zu bevorzugen.</p>
    <p>Moderne Pelletanlagen sind ebenfalls stufenlos regelbar bis auf 30%. Unter Volllast besitzen sie einen feuerungstechnischen Wirkungsgrad von 85-95 %, als Brennwertkessel einen Wirkungsgrad von ~106%.</p>
    <p>Im Vergleich zur Feuerung mit fossilen Energieträgern können 90% der Klimagasemissionen eingespart werden.</p>

    <hr>

    <h2>Hinweise zur Durchführung der Feinanalyse</h2>
    <p>Das größte Energieeinsparpotenzial bei bestehenden Anlagen liegt oft im Austausch des Kessels. Denn ältere Exemplare haben erhebliche Bereitschaftsverluste, zusätzlich sind viele Systeme überdimensioniert. Dies liegt oftmals an zwischenzeitlich erfolgten energetischen Verbesserungen der Gebäudehülle, aber auch daran, dass vielfach von vornherein zu große Kessel installiert wurden.</p>
    <p>Bei einer anstehenden Kesselerneuerung sollte zuerst geklärt werden, ob der vorhandene Energieträger weiter genutzt werden soll. Welche Art der Wärmeerzeugung dann gewählt wird, ist im Wesentlichen von dem Temperaturniveau des vorhandenen Heizungssystems abhängig.</p>

    <h3>1. Schritt: Ermittlung des tatsächlichen Wärmebedarfs</h3>
    <p>Im ersten Schritt wird eine Ist-Analyse durchgeführt, bei der der Energieverbrauch und der tatsächliche Wärmebedarf ermittelt werden. Da viele Kessel zu groß dimensioniert sind, sollte der tatsächliche Wärmebedarf ermittelt werden. Hierzu bestehen z.B. folgende Möglichkeiten:</p>
    <ul>
        <li>Rechnerische Ermittlung des Wärmebedarfs</li>
        <li>Tägliche Messungen oder Ablesungen des Brennstoff/Gasverbrauchs</li>
        <li>Wärmefluss-Messung mit Ultraschallmessgerät über längeren Zeitraum</li>
    </ul>

    <h3>2. Schritt: neuen Kessel entsprechend dem reduzierten Wärmebedarf dimensionieren</h3>
    <p>Anschließend werden Möglichkeiten der Wärmebedarfsreduzierung (Nutzung Wärmerückgewinnung, Veränderung Produktionsprozess, Gebäudemaßnahmen, Wärmeverluste) geprüft und ggf. angesetzt, um die neue Kesselgröße zu dimensionieren.</p>


    <h3>3. Schritt: Ermittlung des tatsächlichen Jahres-Nutzungsgrades (Bestand)</h3>
    <ul>
        <li>Mit Hilfe von Brennstoffzähler und Wärmemengenzähler/Ultraschallmessgerät.</li>
    </ul>
    <p>Hilfsweise über:</p>
    <ul>
        <li>periodische Messung des CO2-Gehaltes der Abgase, der Abgastemperatur, der Rußziffer und des Feuerraumdrucks</li>
        <li>Mittels Oberflächentemperaturfühler wird der Oberflächenverlust des Heizkessels bzw. des Energieerzeugers errechnet.</li>
        <li>Der Wärmeverlust im Abgassystem nach Abschalten des Brenners, der sogenannte Ventilationsverlust, wird gemessen.</li>
    </ul>

    <h3>Folgende weitere Punkte sollten geprüft werden:</h3>
    <ul>
        <li>hydraulisches Entkoppeln der Wärmeerzeuger bei Mehrkesselanlagen</li>
        <li>automatisch arbeitende Folgeschaltung für Mehrkesselanlagen</li>
        <li>Anpassen der Heizkurven und gegebenenfalls Reduzieren der Vorlauf- und Kesseltemperatur</li>
        <li>Installieren von automatisch arbeitenden Abgas oder Verbrennungsluftklappen</li>
        <li>hydraulischer Abgleich des Heizungssystems</li>
        <li>Ergänzung, Verbesserung oder Erneuerung der Wärmedämmung an den Wärmeerzeugern, den Armaturen und der Wärmeverteilung</li>
    </ul>

    <h3>4. Schritt: Ermittlung des neuen Jahres-Nutzungsgrades (Neukessel)</h3>
    <ul>
        <li>Mit EARNS.</li>
    </ul>

    <parameter-help method='boiler' />
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "BoilerHelp",
  components: {
    ParameterHelp
  }
}
</script>

<style scoped>

</style>