<script>

export default {
  name: "ValidationMixin",
  data() {
    return {
      touchedFields: [],
      newlyTouchedFields: [],
      validationErrors: {},
      validationTimer: null,
    }
  },
  computed: {
    fieldErrors() {
      let errors = {};

      for (let field of this.touchedFields) {
        errors[field] = this.validationErrors[this.fields[field]]
      }
      return errors
    },
    valid() {
      return Object.keys(this.validationErrors).length === 0 && !this.validationTimer && this.touchedFields.length > 0
    }
  },
  watch: {
    validationErrors() {
      this.touchedFields = Array.from(new Set([...this.touchedFields, ...this.newlyTouchedFields]))
      this.newlyTouchedFields = []
    },
    data(newData, oldData) {
      if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
        this.validate(newData)
      }
    }
  },
  methods: {
    validate(data) {
      if (this.validationTimer) {
        clearTimeout(this.validationTimer)
      }
      this.validationTimer = setTimeout(() => {

        this.validationTimer = null;
        this.$http.post(this.$options.validationUrl,
            data)
            .then(() => {
              this.validationErrors = {}
            })
            .catch(error => {
              if (error.response.status === 400) {
                this.validationErrors = error.response.data
              }
            })
      }, 600)

    },
    getDataFromFields() {
      let data = {};
      for (let field in this.fields) {
        let fieldValue = this[field]

        data[this.fields[field]] = fieldValue

        if (fieldValue) {
          this.newlyTouchedFields = [...this.newlyTouchedFields, field]
        }
      }

      return data
    },
    setFieldsFromData(data) {
      if (typeof data === "object") {
        for (const [frontend, backend] of Object.entries(this.fields)) {
          let fieldValue = data[backend]
          this[frontend] = fieldValue
          if (fieldValue) {
            this.newlyTouchedFields = [...this.newlyTouchedFields, frontend]
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>