<template>
  <div>
    <h2>Allgemeines</h2>
    <p>
      Detailiertere Hilfe ist aktuell nicht verfügbar. Prüfen Sie die Hilfe zu
      einem späteren Zeitpunkt erneut.
    </p>
    <parameter-help :method="method" />
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "DefaultHelp",
  components: {
    ParameterHelp,
  },
  props: {
    method: String,
  },
};
</script>

<style scoped>
</style>