var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.constructionYear,"label":"Baujahr","hint":_vm.hint.construction_year,"rules":_vm.validationErrors.construction_year,"validate-on-blur":""},model:{value:(_vm.constructionYear),callback:function ($$v) {_vm.constructionYear=_vm._n($$v)},expression:"constructionYear"}},[(!_vm.reference && this.referenceData.construction_year)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("construction_year")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.constructionType,"label":"Bauart","items":_vm.constructionTypes,"item-text":"name","item-value":"id","rules":_vm.validationErrors.construction_type,"validate-on-blur":""},model:{value:(_vm.constructionType),callback:function ($$v) {_vm.constructionType=$$v},expression:"constructionType"}},[(_vm.hint.construction_type)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.construction_type))])]):_vm._e(),(!_vm.reference && this.referenceData.construction_type)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("construction_type")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.nominalPower,"label":"Nennleistung","suffix":"kW","hint":_vm.hint.nominal_power,"rules":_vm.validationErrors.nominal_power,"validate-on-blur":""},model:{value:(_vm.nominalPower),callback:function ($$v) {_vm.nominalPower=_vm._n($$v)},expression:"nominalPower"}},[(!_vm.reference && this.referenceData.nominal_power)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("nominal_power")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.fuel,"label":"Brennstoff","items":_vm.fuelTypes,"disabled":!_vm.constructionType,"item-text":"name","item-value":"id","rules":_vm.validationErrors.fuel,"validate-on-blur":""},model:{value:(_vm.fuel),callback:function ($$v) {_vm.fuel=$$v},expression:"fuel"}},[(_vm.hint.fuel)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.fuel))])]):_vm._e(),(!_vm.reference && this.referenceData.fuel)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("fuel")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.tetaVl,"label":"Vorlauftemperatur","items":_vm.tetaVlItems,"suffix":"°C","rules":_vm.validationErrors.teta_vl,"validate-on-blur":""},model:{value:(_vm.tetaVl),callback:function ($$v) {_vm.tetaVl=$$v},expression:"tetaVl"}},[(_vm.hint.teta_vl)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.teta_vl))])]):_vm._e(),(!_vm.reference && this.referenceData.teta_vl)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("teta_vl")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.tetaRl,"label":"Rücklauftemperatur","items":_vm.tetaRlItems,"suffix":"°C","rules":_vm.validationErrors.teta_rl,"validate-on-blur":""},model:{value:(_vm.tetaRl),callback:function ($$v) {_vm.tetaRl=$$v},expression:"tetaRl"}},[(_vm.hint.teta_rl)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.teta_rl))])]):_vm._e(),(!_vm.reference && this.referenceData.teta_rl)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("teta_rl")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-checkbox',{attrs:{"error-messages":_vm.fieldErrors.temperatureSlip,"label":"Temperaturgleitung"},model:{value:(_vm.temperatureSlip),callback:function ($$v) {_vm.temperatureSlip=$$v},expression:"temperatureSlip"}},[(_vm.hint.temperature_slip)?_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.temperature_slip))])]):_vm._e(),(!_vm.reference && this.referenceData.temperature_slip)?_c('v-btn',{attrs:{"slot":"append","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("temperature_slip")}},slot:"append"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.uptime,"label":"Jährliche Betriebsstunden","suffix":"h","hint":_vm.hint.uptime,"rules":_vm.validationErrors.uptime,"validate-on-blur":""},model:{value:(_vm.uptime),callback:function ($$v) {_vm.uptime=_vm._n($$v)},expression:"uptime"}},[(!_vm.reference && this.referenceData.uptime)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("uptime")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),(_vm.reference)?_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.energyConsumption,"label":"Energiebedarf","suffix":"kWh/a","hint":_vm.hint.energy_consumption,"rules":_vm.validationErrors.energy_consumption,"validate-on-blur":""},model:{value:(_vm.energyConsumption),callback:function ($$v) {_vm.energyConsumption=_vm._n($$v)},expression:"energyConsumption"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }