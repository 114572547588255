<template>
  <v-app id="app">
    <v-app-bar app clipped-left clipped-right color="primary">
      <div class="d-flex flex justify-space-between fill-height">
        <div class="d-flex flex align-center flex-grow-0 fill-height">
          <v-btn plain width="70" :to="{ name: 'Home' }">
            <v-img contain width="70" src="@/assets/logo.png" alt="ROM"></v-img>
          </v-btn>

          <h2 class="ml-2 white--text flex-grow-1">EARNS Web</h2>
        </div>

        <div
          v-if="title"
          class="projectTitle d-flex text-center justify-center flex align-center fill-height"
        >
          <h1 class="white--text flex-grow-1">{{ title }}</h1>
        </div>

        <div
          class="d-flex flex justify-end mr-2 align-center flex-grow-0 fill-height"
        >
          <v-btn v-if="showLogin" class="mr-3" :to="{ name: 'Login' }" text dark
            >Login</v-btn
          >
          <v-btn v-if="showRegister" :to="{ name: 'Register' }" text dark
            >Registrieren</v-btn
          >
          <v-btn v-if="showLogout" @click="logout" text dark>Ausloggen</v-btn>
        </div>
      </div>
    </v-app-bar>
    <v-main>
      <navbar v-if="!showLogin && !showRegister"/>
      <v-row justify="center" class="mt-0" v-if="!title">
        <v-alert
          v-model="cookiesDisabled"
          align="left"
          border="left"
          style="width:90%"
          dense
          prominent
          text
          elevation="3"
          dismissible
          type="error"
        >
          We're sorry but Earns doesn't work properly without
          Cookies enabled. Please enable it to continue.
        </v-alert>
      </v-row>
      <v-container fluid class="pt-6" style="width: 1650px">
        <router-view :key="$route.fullPath" />
      </v-container>
      <v-container class="mt-10">
        <v-footer absolute>
          <v-btn :to="{ name: 'Imprint' }" text light>Impressum</v-btn>
          <v-btn v-if="showContact" :to="{ name: 'Contact' }" text light
            >Kontakt</v-btn
          >
          <v-btn href="mailto:earns@rom-technik.de?subject=Feedback" text light
            >Feedback</v-btn
          >
        </v-footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  components: {
    Navbar
  },
  name: "App",
  data() {
    return {
      underConstructionNote: false,
      cookiesDisabled: false
    };
  },
  computed: {
    showLogin() {
      return !this.$store.getters.isLoggedIn && this.$route.name !== "Login";
    },
    showRegister() {
      return !this.$store.getters.isLoggedIn && this.$route.name !== "Register";
    },
    showLogout() {
      return this.$store.getters.isLoggedIn;
    },
    showContact() {
      return this.$store.getters.isLoggedIn;
    },
    size() {
      return this.$vuetify.breakpoint.name;
    },
    title() {
      return this.$store.state.title;
    }    
  },
  beforeMount() {
    this.$store.commit("initialiseNoCalc")
    this.$store.commit("initialiseNoCalcGroup")
  },
  mounted() {
    if (!navigator.cookieEnabled) {
      this.cookiesDisabled = true
    }
    this.$store
      .dispatch("checkSession")
      .then(() => {
        if (this.$route.name === "Login" || this.$route.name === "Register") {
          this.$router.push("/");
        }
      })
      .catch(() => {
        if (this.$route.name !== "Login" && this.$route.name !== "Register") {
          this.$router.push({ name: "Login" });
        }
      });
  },
  methods: {
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => {})
        .catch(() => {
          this.$toast("Logout nicht erfolgreich.", { color: "error" });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.v-footer {
  justify-content: center;
}

.v-toolbar__content {
  justify-content: space-between;
}

.bar-title {
  color: #fff;
}

.projectTitle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
