import Vue from "vue";
import {formatNumber} from "@/utils";

Vue.prototype.$filters = {
    date(value) {
        return new Date(value).toLocaleDateString("de-DE", {year: "numeric", month: "2-digit", day:"2-digit"})
    },
    number(number) {
        return formatNumber(number)
    }
}


