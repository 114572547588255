<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="amount"
      type="number"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.amount"
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="ratedPower"
      :error-messages="fieldErrors.ratedPower"
      label="Nennleistung"
      :items="ratedPowers"
      item-text="power"
      item-value="id"
      suffix="kW"
      :rules="validationErrors.rated_power"
      validate-on-blur
    >
      <v-tooltip v-if="hint.rated_power" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.rated_power }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.rated_power"
        @click="getReferenceParameter(`rated_power`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-model.number="yearBuilt"
      type="number"
      :error-messages="fieldErrors.yearBuilt"
      label="Baujahr"
      :hint="hint.year_built"
      :rules="validationErrors.year_built"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.year_built"
        @click="getReferenceParameter(`year_built`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-checkbox
      v-model="vsdFu"
      :error-messages="fieldErrors.vsdFu"
      label="VSD / FU"
    >
      <v-tooltip v-if="hint.vsd_fu" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.vsd_fu }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.vsd_fu"
        @click="getReferenceParameter(`vsd_fu`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-text-field
      v-model.number="uptime"
      type="number"
      :error-messages="fieldErrors.uptime"
      label="Jährliche Betriebsstunden"
      suffix="h"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { cacheAdapter } from "@/cache";

export default {
  name: "DryPump1Form",
  extends: BaseForm,

  data() {
    return {
      amount: null,
      ratedPower: null,
      yearBuilt: null,
      vsdFu: false,
      uptime: null,

      fields: {
        amount: "amount",
        ratedPower: "rated_power",
        yearBuilt: "year_built",
        vsdFu: "vsd_fu",
        uptime: "uptime"
      },
      ratedPowers: []
    };
  },

  beforeMount() {
    this.fetchRatedPowers();
  },

  mounted() {
    if (!this.vsdFu) {
      this.vsdFu = false;
    }
  },

  methods: {
    fetchRatedPowers() {
      this.$http
        .get("/api/v1/methods/drypump1/rated-powers/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.ratedPowers = response.data;
        })
        .catch(() => {
          // TODO: error handling
        });
    }
  }
};
</script>

<style scoped></style>
