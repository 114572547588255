<template>
  <v-row justify="center" no-gutters>
    <v-card>
      <v-card-title class="heading justify-center">
        <h4 class="text-h4">Login</h4>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-form @submit.prevent="login" v-model="valid">
          <v-text-field
            v-model="email"
            type="email"
            :rules="emailRules"
            label="E-Mail"
          />
          <v-text-field
            v-model="password"
            type="password"
            :rules="passwordRules"
            label="Passwort"
          />

          <div class="d-flex flex justify-center mt-4">
            <v-btn type="submit" color="primary" :disabled="!valid"
              >Login</v-btn
            >
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { emailMatch } from "@/utils";

export default {
  name: "Login",
  data() {
    return {
      passwordRules: [
        (v) => !!v || "Bitte geben Sie ein Passwort ein um sich einzuloggen."
      ],
      emailRules: [
        (v) =>
          (v && emailMatch(v)) ||
          "Bitte geben Sie eine valide E-Mail Adresse ein um sich einzuloggen."
      ],
      email: null,
      password: null,
      valid: false
    };
  },
  computed: {},
  beforeMount() {},
  methods: {
    login() {
      this.$store
        .dispatch("login", { email: this.email, password: this.password })
        .then(() => {
          this.$toast("Login erfolgreich.", { color: "success" });
          this.$router.push("/");
        })
        .catch((error) => {
          this.$toast(
            error.response.data?.non_field_errors?.[0] ||
              "Login nicht erfolgreich.",
            { color: "error" }
          );
        });
    }
  }
};
</script>

<style scoped>
.v-card {
  width: 100%;
  max-width: 400px;
}
</style>
