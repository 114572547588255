
export function emailMatch(email) {
    return !!email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function djangoChoicesToVuetify(choices) {
    return choices.map(choice => ({value: choice[0], text: choice[1]}))
}

export function formatNumber(number) {
    if (typeof number === "string") {
        number = parseFloat(number);
    }
    if (typeof number !== "number") return null
    return Number(number.toFixed(2)).toLocaleString("de-DE",
        {minimumFractionDigits: 2, maximumFractionDigits: 2}
        )
}
