<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="mainEffortFactor"
      type="number"
      :error-messages="fieldErrors.mainEffortFactor"
      label="Primäre Aufwandszahl"
      :hint="hint.main_effort_factor"
      :rules="validationErrors.main_effort_factor"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.main_effort_factor"
        @click="getReferenceParameter(`main_effort_factor`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="fuel"
      :error-messages="fieldErrors.fuel"
      label="Primärer Energieträger"
      :items="fuels"
      item-text="name"
      item-value="id"
      :rules="validationErrors.fuel"
      validate-on-blur
    >
      <v-tooltip v-if="hint.fuel" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.fuel }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.fuel"
        @click="getReferenceParameter(`fuel`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-if="reference"
      v-model.number="energyConsumption"
      type="number"
      :error-messages="fieldErrors.energyConsumption"
      label="Energiebedarf"
      suffix="kWh/a"
      :hint="hint.energy_consumption"
      :rules="validationErrors.energy_consumption"
      validate-on-blur
    />

    <v-autocomplete
      v-model="passiveGeneratedEnergy"
      :error-messages="fieldErrors.passiveGeneratedEnergy"
      label="Passiv erzeugte Energieform"
      :items="energies"
      item-text="name"
      item-value="id"
      :rules="validationErrors.passive_generated_energy"
      validate-on-blur
    >
      <v-tooltip
        v-if="hint.passive_generated_energy"
        slot="append-outer"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.passive_generated_energy }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.passive_generated_energy"
        @click="getReferenceParameter(`passive_generated_energy`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-show="passiveGeneratedEnergy"
      v-model.number="passiveEffortFactor"
      type="number"
      :error-messages="fieldErrors.passiveEffortFactor"
      label="Passive Aufwandszahl"
      :hint="hint.passive_effort_factor"
      :rules="validationErrors.passive_effort_factor"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.passive_effort_factor"
        @click="getReferenceParameter(`passive_effort_factor`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="supplyEnergyFriction"
      type="number"
      :error-messages="fieldErrors.supplyEnergyFriction"
      label="Anteil elektrischer Hilfsenergie"
      :hint="hint.supply_energy_friction"
      :rules="validationErrors.supply_energy_friction"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.supply_energy_friction"
        @click="getReferenceParameter(`supply_energy_friction`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { cacheAdapter } from "@/cache";

export default {
  name: "FreeGeneratorForm",
  extends: BaseForm,

  data() {
    return {
      fuel: null,
      energyConsumption: null,
      mainEffortFactor: null,
      passiveGeneratedEnergy: null,
      passiveEffortFactor: null,
      supplyEnergyFriction: null,

      fields: {
        fuel: "fuel",
        energyConsumption: "energy_consumption",
        mainEffortFactor: "main_effort_factor",
        passiveGeneratedEnergy: "passive_generated_energy",
        passiveEffortFactor: "passive_effort_factor",
        supplyEnergyFriction: "supply_energy_friction"
      },
      energies: [],
      fuels: []
    };
  },

  beforeMount() {
    this.fetchEnergies();
    this.fetchFuels();
  },

  mounted() {
    if (!this.supplyEnergyFriction) {
      this.supplyEnergyFriction = 0;
    }
    if (!this.passiveEffortFactor) {
      this.passiveEffortFactor = 0;
    }
  },

  methods: {
    fetchEnergies() {
      this.$http
        .get("/api/v1/methods/freegenerator/energies/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.energies = [{ id: null, name: "keine" }, ...response.data];
        })
        .catch(() => {});
    },
    fetchFuels() {
      this.$http
        .get("/api/v1/methods/freegenerator/fuels/", { adapter: cacheAdapter })
        .then((response) => {
          this.fuels = response.data;
        })
        .catch(() => {});
    }
  },

  watch: {
    passiveGeneratedEnergy(value) {
      if (!value) {
        this.passiveEffortFactor = 0;
      }
    }
  }
};
</script>

<style scoped></style>
