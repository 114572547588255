<template>
  <v-form @submit.prevent="" ref="form">
    <v-autocomplete
      v-model="chillerType"
      :error-messages="fieldErrors.chillerType"
      :items="chillerTypes"
      label="Typ der Kältemaschine"
      :rules="validationErrors.chiller_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.chiller_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.chiller_type }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.chiller_type"
        @click="getReferenceParameter(`chiller_type`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-checkbox
      v-show="isAC"
      v-model="knownSeer"
      :error-messages="fieldErrors.knownSeer"
      label="SEER bekannt"
    >
      <v-tooltip v-if="hint.chiller_type" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.knownSeer }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.known_seer"
        @click="getReferenceParameter(`known_seer`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-text-field
      v-show="knownSeer & isAC"
      v-model="knownSeerValue"
      :disabled="!knownSeer"
      :error-messages="fieldErrors.knownSeerValue"
      label="Jahresarbeitzahl"
      :hint="hint.known_seer_value"
      :rules="validationErrors.known_seer_value"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.known_seer_value"
        @click="getReferenceParameter(`known_seer_value`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <div v-show="isKKM | (isAC & !knownSeer)">
      <v-row no-gutters>
        <v-col cols="11">
          <chiller-usage-type
            v-show="chillerType"
            v-model="usageType1"
            :chiller-type="chillerType"
            :field-errors="fieldErrors.usageType1 || {}"
            :free-cooling="freeCooling"
            :known-seer="knownSeer"
            initially-checked
            label="Nutzungsart 1"
            :rules="validationErrors.usage_type_1"
            :hint="hint"
            validate-on-blur
          />
        </v-col>
        <v-col cols="1">
          <v-row justify="end" no-gutters>
            <v-btn
              v-if="
                !reference &&
                  this.plannedData.usage_type_1 &&
                  this.referenceData.usage_type_1 &&
                  (this.referenceData.usage_type_1.usage_profile ||
                    this.referenceData.usage_type_1.usage_share ||
                    this.referenceData.usage_type_1.operation_mode)
              "
              @click="getReferenceParameter(`usage_type_1`)"
              slot="append-outer"
              title="Referenzwerte der Nutzungsart 1 übernehmen"
              icon
              class="ml-1"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="11">
          <chiller-usage-type
            v-show="chillerType"
            v-model="usageType2"
            :chiller-type="chillerType"
            :field-errors="fieldErrors.usageType2 || {}"
            :free-cooling="freeCooling"
            :known-seer="knownSeer"
            :disabled="!usageType1"
            label="Nutzungsart 2"
            :rules="validationErrors.usage_type_2"
            :hint="hint"
            validate-on-blur
          />
        </v-col>
        <v-col cols="1">
          <v-row justify="end" no-gutters>
            <v-btn
              v-if="
                !reference &&
                  this.plannedData.usage_type_2 &&
                  this.referenceData.usage_type_2 &&
                  (this.referenceData.usage_type_2.usage_profile ||
                    this.referenceData.usage_type_2.usage_share ||
                    this.referenceData.usage_type_2.operation_mode)
              "
              @click="getReferenceParameter(`usage_type_2`)"
              slot="prepend"
              title="Referenzwerte der Nutzungsart 2 übernehmen"
              icon
              class="ml-1"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="11">
          <chiller-usage-type
            v-show="chillerType"
            v-model="usageType3"
            :chiller-type="chillerType"
            :field-errors="fieldErrors.usageType3 || {}"
            :free-cooling="freeCooling"
            :known-seer="knownSeer"
            :disabled="!usageType2"
            label="Nutzungsart 3"
            :rules="validationErrors.usage_type_3"
            :hint="hint"
            validate-on-blur
          />
        </v-col>
        <v-col cols="1">
          <v-row justify="end" no-gutters>
            <v-btn
              v-if="
                !reference &&
                  this.plannedData.usage_type_3 &&
                  this.referenceData.usage_type_3 &&
                  (this.referenceData.usage_type_3.usage_profile ||
                    this.referenceData.usage_type_3.usage_share ||
                    this.referenceData.usage_type_3.operation_mode)
              "
              @click="getReferenceParameter(`usage_type_3`)"
              slot="prepend"
              title="Referenzwerte der Nutzungsart 3 übernehmen"
              icon
              class="align-right"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-text-field
      v-model.number="nominalCoolingPower"
      :error-messages="fieldErrors.nominalCoolingPower"
      label="Nennkälteleistung"
      suffix="kW"
      :hint="hint.nominal_cooling_power"
      :rules="validationErrors.nominal_cooling_power"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.nominal_cooling_power"
        @click="getReferenceParameter(`nominal_cooling_power`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-show="isKKM"
      v-model.number="nominalElectricalDrivingPower"
      :error-messages="fieldErrors.nominalElectricalDrivingPower"
      label="Elektrische Nennantriebsleistung"
      suffix="kW"
      :hint="hint.nominal_electrical_driving_power"
      :rules="validationErrors.nominal_electrical_driving_power"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.nominal_electrical_driving_power"
        @click="getReferenceParameter(`nominal_electrical_driving_power`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-if="reference"
      v-show="isKKM || isAC"
      v-model.number="finalElectricEnergyConsumption"
      :error-messages="fieldErrors.finalElectricEnergyConsumption"
      label="Jährlicher elektrischer Energiebedarf"
      suffix="kWh/a"
      :hint="hint.final_electric_energy_consumption"
      :rules="validationErrors.final_electric_energy_consumption"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference && this.referenceData.final_electric_energy_consumption
        "
        @click="getReferenceParameter(`final_electric_energy_consumption`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model="uptime"
      :error-messages="fieldErrors.uptime"
      label="Jährliche Betriebsstunden"
      suffix="h/a"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-show="isKKM"
      v-model="coolingType"
      :error-messages="fieldErrors.coolingType"
      :items="coolingTypes"
      label="Rückkühlung"
      :rules="validationErrors.cooling_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.cooling_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.cooling_type }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.cooling_type"
        @click="getReferenceParameter(`cooling_type`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-show="isAC && !knownSeer"
      v-model="acType"
      :error-messages="fieldErrors.acType"
      :items="acTypes"
      label="Anlagensystem"
      :rules="validationErrors.ac_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.ac_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.ac_type }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.ac_type"
        @click="getReferenceParameter(`ac_type`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-show="isKKM || (isAC && !knownSeer)"
      v-model="partLoadControl"
      :error-messages="fieldErrors.partLoadControl"
      :items="partLoadChoices"
      label="Art des Verdichters und/oder der Teillastregelung"
      :rules="validationErrors.part_load_control"
      validate-on-blur
    >
      <v-tooltip v-if="hint.part_load_control" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.part_load_control }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.part_load_control"
        @click="getReferenceParameter(`part_load_control`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-show="isWaterKKM"
      v-model="coolantInlet"
      :error-messages="fieldErrors.coolantInlet"
      :items="coolantInlets"
      label="Kühlwassereintritt Kältemaschine"
      :rules="validationErrors.coolant_inlet"
      validate-on-blur
    >
      <v-tooltip v-if="hint.coolant_inlet" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.coolant_inlet }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.coolant_inlet"
        @click="getReferenceParameter(`coolant_inlet`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-show="isAirKKM"
      v-model="coolerMode"
      :error-messages="fieldErrors.coolerMode"
      :items="coolerModes"
      label="Art der Rückkühlung"
      :rules="validationErrors.cooler_mode"
      validate-on-blur
    >
      <v-tooltip v-if="hint.cooler_mode" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.cooler_mode }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.cooler_mode"
        @click="getReferenceParameter(`cooler_mode`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-show="isWaterKKM | isAirKKM"
      v-model="coolerType"
      :error-messages="fieldErrors.coolerType"
      :items="coolerTypes"
      label="Art des Rückkühlers"
      :rules="validationErrors.cooler_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.cooler_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.cooler_type }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.cooler_type"
        @click="getReferenceParameter(`cooler_type`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-show="isSorb"
      v-model.number="nominalHeatingPower"
      :error-messages="fieldErrors.nominalHeatingPower"
      label="Nennheizwärmeleistung"
      suffix="kW"
      :hint="hint.nominal_heating_power"
      :rules="validationErrors.nominal_heating_power"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.nominal_heating_power"
        @click="getReferenceParameter(`nominal_heating_power`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-if="reference"
      v-show="isSorb"
      v-model.number="finalThermalEnergyConsumption"
      :error-messages="fieldErrors.finalThermalEnergyConsumption"
      label="Jährlicher thermischer Energiebedarf"
      suffix="kWh/a"
      :hint="hint.final_thermal_energy_consumption"
      :rules="validationErrors.final_thermal_energy_consumption"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.final_thermal_energy_consumption"
        @click="getReferenceParameter(`final_thermal_energy_consumption`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-show="isSorb"
      v-model="coolantSolventPair"
      :error-messages="fieldErrors.coolantSolventPair"
      :items="coolantSolventPairs"
      label="Kältemittel/Lösungsmittel Paar"
      :rules="validationErrors.coolant_solvent_pair"
      validate-on-blur
    >
      <v-tooltip v-if="hint.coolant_solvent_pair" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.coolant_solvent_pair }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.coolant_solvent_pair"
        @click="getReferenceParameter(`coolant_solvent_pair`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-checkbox
      v-show="isKKM"
      v-model="freeCooling"
      :error-messages="fieldErrors.freeCooling"
      label="Freie Kühlung"
    >
      <v-tooltip v-if="hint.free_cooling" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.free_cooling }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.free_cooling"
        @click="getReferenceParameter(`free_cooling`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-autocomplete
      v-show="freeCooling"
      v-model="freeCoolingMode"
      :error-messages="fieldErrors.freeCoolingMode"
      :items="freeCoolingModes"
      label="Betriebsweise der freien Kühlung"
      no-data-text="Rückkühlung wählen"
      :rules="validationErrors.free_cooling_mode"
      validate-on-blur
    >
      <v-tooltip v-if="hint.free_cooling_mode" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.free_cooling_mode }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.free_cooling_mode"
        @click="getReferenceParameter(`free_cooling_mode`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import ChillerUsageType from "@/components/methods/ChillerUsageType";
import { djangoChoicesToVuetify } from "@/utils";
import { cacheAdapter } from "@/cache";

export default {
  name: "ChillerForm",
  extends: BaseForm,
  components: {
    ChillerUsageType
  },
  data() {
    return {
      usageType1: {
        usageProfile: null,
        usageShare: null,
        operationMode: null,
        humidityRequirement: null,
        heatRecovery: null
      },
      usageType2: {
        usageProfile: null,
        usageShare: null,
        operationMode: null,
        humidityRequirement: null,
        heatRecovery: null
      },
      usageType3: {
        usageProfile: null,
        usageShare: null,
        operationMode: null,
        humidityRequirement: null,
        heatRecovery: null
      },
      chillerType: null,
      nominalCoolingPower: null,
      nominalElectricalDrivingPower: null,
      finalElectricEnergyConsumption: null,
      coolingType: null,
      partLoadControl: null,
      coolantInlet: null,
      coolerMode: null,
      coolerType: null,
      nominalHeatingPower: null,
      finalThermalEnergyConsumption: null,
      coolantSolventPair: null,
      uptime: null,
      knownSeer: null,
      knownSeerValue: null,
      acType: null,
      freeCooling: false,
      freeCoolingMode: null,

      fields: {
        usageType1: "usage_type_1",
        usageType2: "usage_type_2",
        usageType3: "usage_type_3",
        chillerType: "chiller_type",
        nominalCoolingPower: "nominal_cooling_power",
        nominalElectricalDrivingPower: "nominal_electrical_driving_power",
        finalElectricEnergyConsumption: "final_electric_energy_consumption",
        coolingType: "cooling_type",
        partLoadControl: "part_load_control",
        coolantInlet: "coolant_inlet",
        coolerMode: "cooler_mode",
        coolerType: "cooler_type",
        nominalHeatingPower: "nominal_heating_power",
        finalThermalEnergyConsumption: "final_thermal_energy_consumption",
        coolantSolventPair: "coolant_solvent_pair",
        uptime: "uptime",
        knownSeer: "known_seer",
        knownSeerValue: "known_seer_value",
        acType: "ac_type",
        freeCooling: "free_cooling",
        freeCoolingMode: "free_cooling_mode"
      },

      chillerTypes: [],
      coolingTypes: [],
      coolerModes: [],
      coolerTypes: [],
      waterCooledChoices: [],
      partLoadChoices: [],
      coolantInlets: [],
      coolantSolventPairs: [],
      acTypes: [],
      freeCoolingModes: []
    };
  },

  beforeMount() {
    this.fetchChillerTypes();
    this.fetchCoolingTypes();
    this.fetchCoolerModes();
    this.fetchCoolerTypes();
    this.fetchCoolantInlets();
    this.fetchCoolantSolventPairs();
    this.fetchAcTypes();
  },

  mounted() {
    if (!this.freeCooling) {
      this.freeCooling = false;
    }
    if (!this.knownSeer) {
      this.knownSeer = false;
    }
  },

  methods: {
    fetchChillerTypes() {
      this.$http
        .get("/api/v1/methods/chiller/chiller-types/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.chillerTypes = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchCoolingTypes() {
      this.$http
        .get("/api/v1/methods/chiller/cooling-types/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.coolingTypes = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchCoolerModes() {
      this.$http
        .get("/api/v1/methods/chiller/cooler-modes/", { adapter: cacheAdapter })
        .then((response) => {
          this.coolerModes = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchCoolerTypes() {
      this.$http
        .get("/api/v1/methods/chiller/cooler-types/", {
          params: {
            cooling_type: this.coolingType,
            cooler_mode: this.coolerMode
          },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.coolerTypes = djangoChoicesToVuetify(response.data);

          if (this.coolerTypes.length === 1) {
            this.coolerType = this.coolerTypes[0].value;
          }
        })
        .catch(() => {});
    },

    fetchCoolantSolventPairs() {
      this.$http
        .get("/api/v1/methods/chiller/coolant-solvent-pairs/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.coolantSolventPairs = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchPartLoadChoices() {
      this.$http
        .get("/api/v1/methods/chiller/part-load-control/", {
          params: {
            chiller_type: this.chillerType,
            cooling_type: this.coolingType,
            ac_type: this.acType
          },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.partLoadChoices = djangoChoicesToVuetify(response.data);

          if (this.partLoadChoices.length === 1) {
            this.partLoadControl = this.partLoadChoices[0].value;
          }
        })
        .catch(() => {});
    },

    fetchCoolantInlets() {
      this.$http
        .get("/api/v1/methods/chiller/coolant-inlet/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.coolantInlets = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchAcTypes() {
      this.$http
        .get("/api/v1/methods/chiller/ac-types/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.acTypes = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchFreeCoolingModes() {
      this.$http
        .get("/api/v1/methods/chiller/free-cooling-modes/", {
          params: { cooling_type: this.coolingType },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.freeCoolingModes = djangoChoicesToVuetify(response.data);
          this.freeCoolingMode = null;
          if (this.freeCoolingModes.length == 1) {
            this.freeCoolingMode = this.freeCoolingModes[0].value;
          }
        })
        .catch(() => {});
    }
  },

  watch: {
    isKKM(value) {
      if (!value) {
        // set Kompressionskältemaschinen specific variables to zero after closing
        this.nominalElectricalDrivingPower = null;
        this.finalElectricEnergyConsumption = null;
        this.coolingType = null;
        this.partLoadControl = null;
        this.coolantInlet = null;
        this.coolerMode = null;
        this.cooler_type = null;
      }
    },
    isSorb(value) {
      if (!value) {
        // set Kompressionskältemaschinen specific variables to zero after closing
        this.nominalHeatingPower = null;
        this.finalThermalEnergyConsumption = null;
        this.coolantSolventPair = null;
      }
    },
    isAC(value) {
      if (!value) {
        // set Kompressionskältemaschinen specific variables to zero after closing
        this.knownSeer = null;
        this.acType = null;
        this.knownSeerValue = null;
      }
    },
    coolingType: {
      handler() {
        if (this.coolingType != null) {
          this.fetchPartLoadChoices();
          this.fetchFreeCoolingModes();
        }
        this.fetchCoolerTypes();
      },
      immediate: true
    },
    chillerType: {
      handler() {
        if (this.coolingType != null) {
          this.fetchPartLoadChoices();
        }
      }
    },
    acType: {
      handler() {
        if (this.acType != null) {
          this.fetchPartLoadChoices();
        }
      }
    },
    coolerMode: {
      handler: "fetchCoolerTypes"
    }
  },

  computed: {
    isKKM() {
      return this.chillerType == 1;
    },
    isSorb() {
      return this.chillerType == 2;
    },
    isAC() {
      return this.chillerType == 3;
    },
    isWaterKKM() {
      return this.isKKM && this.coolingType == 1;
    },
    isAirKKM() {
      return this.isKKM && this.coolingType == 2;
    }
  }
};
</script>

<style scoped></style>
