<template>
  <v-expansion-panels accordion hover>
    <v-expansion-panel :disabled="!projectValid">
      <v-expansion-panel-header>
        <div v-show="projectValid" class="heading">
          Energiediagramme
        </div>
        <div v-show="!projectValid" class="heading">
          Die Vorschau ist nur bei gültigen Parametern verfügbar!
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-carousel
          hide-delimiters
          cycle
        >
          <v-carousel-item>
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <Transition>
              <StatsPrice
                v-show="projectValid"
                :projectData="projectData"
                :update="projectValid"
              />
            </Transition>
            </v-row>
          </v-carousel-item>
          <v-carousel-item>
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <Transition>
              <StatsCo2
                v-show="projectValid"
                :projectData="projectData"
                :update="projectValid"
              />
            </Transition>
            </v-row>
          </v-carousel-item>
          <v-carousel-item>
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <Transition>
              <StatsPerformance
                v-show="projectValid"
                :projectData="projectData"
                :update="projectValid"
              />
            </Transition>
            </v-row>
          </v-carousel-item>
          <v-carousel-item>
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <Transition>
              <StatsConsumption
                v-show="projectValid"
                :projectData="projectData"
                :update="projectValid"
              />
            </Transition>
            </v-row>
          </v-carousel-item><v-carousel-item>
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <Transition>
              <StatsCost
                v-show="projectValid"
                :projectData="projectData"
                :update="projectValid"
              />
            </Transition>
            </v-row>
          </v-carousel-item><v-carousel-item>
            <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
            <Transition>
              <StatsHistoricPrice 
                v-show="projectValid"
                :projectData="projectData"
                :update="projectValid"
              />
            </Transition>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import "@/assets/StyleMethodHelp.css";
import StatsPrice from "./StatsPrice.vue";
import StatsCo2 from "./StatsCo2.vue";
import StatsPerformance from "./StatsPerformance.vue";
import StatsConsumption from "./StatsConsumption.vue";
import StatsCost from "./StatsCost.vue";
import StatsHistoricPrice from "./StatsHistoricPrice.vue";

export default {
  name: "Statscard",
  components: {
    StatsPrice,
    StatsCo2,
    StatsPerformance,
    StatsConsumption,
    StatsCost,
    StatsHistoricPrice,
  },
  props: {
    projectData: null,
    projectValid: null
  }
};
</script>

<style scoped>
.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  /* max-width: 980px; */
  margin: 0 auto;
  padding: 45px;
  max-width: 80ch;
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: 2s;
}

.v-enter-from,
.v-leave-to {
  opacity: 100;
}
.padding {
  padding: 15px;
}
div.heading {
  color: black;
  font-size: 1.25rem;
}
</style>
