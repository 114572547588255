var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-center pointer chiller-usage-type-label",class:{
      'error--text': _vm.hasError && _vm.isCollapsed,
      'grey--text': _vm.fieldsDisabled
    },on:{"click":_vm.onCollapseClick}},[_c('v-simple-checkbox',{attrs:{"value":_vm.isUsed,"disabled":_vm.disabled,"dense":"","hide-details":""},on:{"input":function($event){_vm.isChecked = !!$event},"click":function($event){$event.stopPropagation();}}}),_c('h3',[_vm._v(_vm._s(_vm.label))]),_c('v-icon',[_vm._v(_vm._s(_vm.isCollapsed ? "mdi-chevron-right" : "mdi-chevron-down"))]),_c('v-spacer')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isCollapsed),expression:"!isCollapsed"}],staticClass:"chiller-usage-type-input-wrapper"},[_c('v-autocomplete',{attrs:{"error-messages":_vm.filteredFieldErrors.usage_profile,"items":_vm.usageProfiles,"disabled":_vm.fieldsDisabled,"dense":"","hide-details":"auto","label":"Nutzungsprofil","item-text":"name","item-value":"id","rules":_vm.fieldErrors.usage_profile,"validate-on-blur":""},on:{"input":function($event){return _vm.didTouchField('usage_profile')}},model:{value:(_vm.usageProfile),callback:function ($$v) {_vm.usageProfile=$$v},expression:"usageProfile"}},[(_vm.hint.usage_profile)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.usage_profile))])]):_vm._e()],1),_c('v-text-field',{attrs:{"error-messages":_vm.filteredFieldErrors.usage_share,"disabled":_vm.fieldsDisabled,"dense":"","hide-details":"auto","label":"Anteil der Nutzungsart","suffix":"%","hint":_vm.hint.usage_share,"rules":_vm.fieldErrors.usage_share,"validate-on-blur":""},on:{"input":function($event){return _vm.didTouchField('usage_share')}},model:{value:(_vm.usageShare),callback:function ($$v) {_vm.usageShare=_vm._n($$v)},expression:"usageShare"}}),_c('v-autocomplete',{attrs:{"error-messages":_vm.filteredFieldErrors.operation_mode,"items":_vm.operationModes,"disabled":_vm.fieldsDisabled || _vm.isAC,"dense":"","hide-details":"auto","label":"Betriebsart","rules":_vm.fieldErrors.operation_mode,"validate-on-blur":""},on:{"input":function($event){return _vm.didTouchField('operation_mode')}},model:{value:(_vm.operationMode),callback:function ($$v) {_vm.operationMode=$$v},expression:"operationMode"}},[(_vm.hint.operation_mode)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.operation_mode))])]):_vm._e()],1),(_vm.isAHU)?_c('v-autocomplete',{attrs:{"error-messages":_vm.filteredFieldErrors.humidity_requirement,"items":_vm.humidityRequirements,"disabled":_vm.fieldsDisabled,"dense":"","hide-details":"auto","label":"Feuchteanforderung","no-data-text":"Betriebsart wählen","rules":_vm.fieldErrors.humidity_requirement,"validate-on-blur":""},on:{"input":function($event){return _vm.didTouchField('humidity_requirement')}},model:{value:(_vm.humidityRequirement),callback:function ($$v) {_vm.humidityRequirement=$$v},expression:"humidityRequirement"}},[(_vm.hint.humidity_requirement)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.humidity_requirement))])]):_vm._e()],1):_vm._e(),(_vm.isAHU)?_c('v-autocomplete',{attrs:{"error-messages":_vm.filteredFieldErrors.heat_recovery,"items":_vm.heatRecoveries,"disabled":_vm.fieldsDisabled,"dense":"","hide-details":"auto","label":"Wärmerückgewinnung","no-data-text":"Feuchteanforderung wählen","rules":_vm.fieldErrors.heat_recovery,"validate-on-blur":""},on:{"input":function($event){return _vm.didTouchField('heat_recovery')}},model:{value:(_vm.heatRecovery),callback:function ($$v) {_vm.heatRecovery=$$v},expression:"heatRecovery"}},[(_vm.hint.heat_recovery)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.heat_recovery))])]):_vm._e()],1):_vm._e(),(_vm.freeCooling)?_c('v-autocomplete',{attrs:{"error-messages":_vm.filteredFieldErrors.temperature_free_cooling,"items":_vm.temperaturesFreeCooling,"disabled":_vm.fieldsDisabled,"dense":"","hide-details":"auto","label":"Temperaturniveau freie Kühlung","no-data-text":"Nutzungsprofil wählen","suffix":"°C","rules":_vm.fieldErrors.temperature_free_cooling,"validate-on-blur":""},on:{"input":function($event){return _vm.didTouchField('temperature_free_cooling')}},model:{value:(_vm.temperatureFreeCooling),callback:function ($$v) {_vm.temperatureFreeCooling=$$v},expression:"temperatureFreeCooling"}},[(_vm.hint.temperature_free_cooling)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.temperature_free_cooling))])]):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }