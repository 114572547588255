<template>
  <v-card>
    <v-card-title class="headline">
      Analyse
    </v-card-title>

    <div v-if="errorResponse">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Es sind Fehler bei der Berechnung aufgetreten:</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="error in errorResponse" :key="error">
              <td>
                {{ error }}
                <v-tooltip slot="append-outer" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information</v-icon>
                  </template>
                  <span>
                    Die fehlende Größe bezieht sich auf die Nutzenergie, z.B.
                    beim Wämreenergiepreis bitte den Preis für alle verwendeten
                    Endenergien (z.B. Gas LL, Öl, etc.) in Projektdetails
                    angeben.
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </div>

    <div v-if="(warningResponse || []).length">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Folgende Warnung ist bei der Berechnung aufgetreten:</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="warning in warningResponse" :key="warning">
              <td>
                {{ warning }}
                <v-tooltip slot="append-outer" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-information</v-icon>
                  </template>
                  <span>
                    Die fehlende Größe in Projektdetails angeben.
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </div>

    <div v-if="actionResults">
      <v-card-text>
        <v-simple-table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Maßnahme</th>
              <th class="text-right">Einsparungen [€/a]</th>
              <th class="text-right">Invest [€]</th>
              <th class="text-right" v-for="eid in energies" :key="eid">
                {{ getEnergyName(eid) }} [kWh]
              </th>
              <th class="text-right" />
              <th class="text-right" />
            </tr>
          </thead>
          <tbody>
            <template v-for="actionGroup in actionGroupResults">
              <tr :key="actionGroup.name">
                <!--Duplicate keys detected: '1'. This may cause an update error.-->
                <td
                  @click="toggleGroup(actionGroup.id)"
                  colspan="2"
                  class="grouptitle"
                  :class="{
                    opened: !toggledActionGroup.includes(actionGroup.id)
                  }"
                >
                  <v-icon
                    v-if="!toggledActionGroup.includes(actionGroup.id)"
                    small
                    >mdi-chevron-down</v-icon
                  >
                  <v-icon v-else small>mdi-chevron-right</v-icon>

                  {{ getGroupName(actionGroup.id) }}
                </td>
                <td
                  @click="toggleGroup(actionGroup.id)"
                  class="text-right group"
                >
                  {{ $filters.number(actionGroup.total.savings) }}
                </td>
                <td
                  @click="toggleGroup(actionGroup.id)"
                  class="text-right group"
                >
                  {{ $filters.number(actionGroup.total.invest) || "-" }}
                </td>
                <td
                  @click="toggleGroup(actionGroup.id)"
                  class="text-right group"
                  v-for="eid in energies"
                  :key="eid"
                >
                  {{
                    $filters.number(actionGroup.total.energySavings[eid]) || "-"
                  }}
                </td>
                <td class="group">
                  <v-btn
                    :href="docxDownloadHrefGroup(actionGroup.id)"
                    color="primary"
                    icon
                    title="Kurzbericht dieser Gruppe als Word Export"
                  >
                    <v-icon>mdi-file-word</v-icon>
                  </v-btn>
                </td>
                <td class="group"></td>
              </tr>
              <ProjectCalcActionRow
                v-show="toggledActionGroup.includes(actionGroup.id)"
                v-for="action in actionGroup.actions"
                :key="action.id"
                indent
                :action="action"
                :energies="energies"
                :methodTexts="methodTexts"
                class="action"
              />
            </template>

            <ProjectCalcActionRow
              v-for="action in actionResults"
              :key="action.id"
              :action="action"
              :energies="energies"
              :methodTexts="methodTexts"
              class="action"
            />

            <tr style="font-weight: bold">
              <td>Summe</td>
              <td></td>
              <td class="text-right">
                {{ $filters.number(totalResult.savings) }}
              </td>
              <td class="text-right">
                {{ $filters.number(totalResult.invest) || "-" }}
              </td>
              <td class="text-right" v-for="eid in energies" :key="eid">
                {{ $filters.number(totalResult.energySavings[eid]) }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row justify="end" no-gutters class="mt-4">
          <v-btn
            :href="docxDownloadHref"
            color="primary"
            class="mr-4"
            title="Bericht aller Maßnahmen als Word Export"
          >
            <v-icon left>mdi-file-word</v-icon>
            Word Bericht
          </v-btn>
          <v-btn
            :href="zipDownloadHref"
            color="primary"
            class="mr-4"
            title="Alle Kurzberichte der Maßnahmen als Zip Export"
          >
            <v-icon left>mdi-folder-zip</v-icon>
            Kurzberichte Zip
          </v-btn>
          <v-btn
            :href="excelDownloadHref"
            color="primary"
            title="Analyse Tabelle als Excel Export"
          >
            <v-icon left>mdi-file-excel</v-icon>
            Excel
          </v-btn>
        </v-row>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import ProjectCalcActionRow from "@/components/projectcalc/ProjectCalcActionRow";
export default {
  name: "ProjectCalc",
  components: { ProjectCalcActionRow },
  data() {
    return {
      toggledActionGroup: [],
      methodTexts: null,
      calcResponse: null,
      errorResponse: null,
      warningResponse: null,

      fuels: {},
      savingSum: 0,
      investSum: 0,
      actionCalcList: null
    };
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    project() {
      return this.$store.state.currentProject;
    },
    energies: function() {
      let actionEnergies = this.actionResults
        .map((res) => (res.energySavings ? Object.keys(res.energySavings) : []))
        .flat();
      let result = Array.from(
        new Set([
          ...Object.keys(this.totalResult.energySavings),
          ...actionEnergies
        ])
      ).map((x) => parseInt(x));

      result = result.filter((x) => x <= 12);
      result.sort((a, b) => a - b);
      return result;
    },
    actionGroupResults() {
      return this.calcResponse?.result.actionGroups.filter(
        (i) => !this.$store.state.noCalcGroup.includes(i.id)
      );
    },
    groups() {
      return (this.$store.state.currentProjectActionGroups || []).reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {}
      );
    },
    actionResults() {
      return this.calcResponse?.result.actions.filter(
        (i) => !this.$store.state.noCalc.includes(i.id)
      );
    },
    totalResult() {
      return this.calcResponse?.result.total;
    },
    resultId() {
      return this.calcResponse?.id || null;
    },
    excelDownloadHref() {
      return `/api/v1/results/${this.resultId}/excel/`;
    },
    docxDownloadHref() {
      return `/api/v1/projects/${this.projectId}/docx/`;
    },
    zipDownloadHref() {
      return `/api/v1/projects/${this.projectId}/action_reports_zip/`;
    },
    docxDownloadHrefGroup() {
      return (actionGroupId) =>
        `/api/v1/actiongroups/${actionGroupId}/action_group_report/`;
    },
    actionList() {
      return this.$store.state.currentProjectActions || [];
    }
  },
  watch: {
    actionList(actionList) {
      if (actionList) {
        this.calcActions();
      }
    },
    project: {
      handler(project) {
        if (project) {
          this.$store.commit("setTitle", this.project.name);
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    this.fetch();
    this.fetchEnergyNames();
    this.fetchMethodTexts();
  },
  methods: {
    calcActionsCalcList() {
      this.actionCalcList = this.$store.state.currentProjectActions.map(
        (i) => i.id
      );
      this.actionList
        .map((i) => i.id)
        .forEach((element) => {
          if (this.$store.state.noCalc.includes(element)) {
            this.actionCalcList = this.actionCalcList.filter(
              (i) => i !== element
            );
          }
        });
    },
    fetchMethodTexts() {
      this.$http.get("/api/v1/method_text/").then((response) => {
        this.methodTexts = response.data;
      });
    },
    fetch() {
      this.$store.dispatch("fetchCurrentProject", this.projectId);
    },
    getEnergyName(eid) {
      return this.fuels[eid] ?? "Energieart n/a";
    },
    getGroupName(groupId) {
      return this.groups[groupId] ? this.groups[groupId].name : "n/a";
    },
    calcActions() {
      this.calcActionsCalcList();
      this.$http
        .post("/api/v1/projects/" + this.projectId + "/calc/", {
          params: { actions: this.actionCalcList }
        })
        .then((response) => {
          this.calcResponse = response.data;
          this.warningResponse = response.data.result.warnings;
        })
        .catch((error) => {
          if (error.response.status < 500) {
            this.errorResponse = error.response.data;
          }
          this.$toast("Die Berechnung konnte nicht durchgeführt werden.", {
            color: "error"
          });
        });
    },
    fetchEnergyNames() {
      this.$http
        .get("/api/v1/projects/fuels/")
        .then((response) => {
          for (let energy of response.data) {
            this.fuels[energy.id] = energy.name;
          }

          this.fuels = { ...this.fuels };
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    toggleGroup(id) {
      const index = this.toggledActionGroup.indexOf(id);
      if (index > -1) {
        this.toggledActionGroup.splice(index, 1);
      } else {
        this.toggledActionGroup.push(id);
      }
    }
  }
};
</script>

<style scoped>
tbody tr:hover .group {
  background-color: whitesmoke !important;
}
tbody tr .group {
  background-color: whitesmoke !important;
  cursor: pointer;
}
tbody tr .grouptitle {
  background-color: whitesmoke !important;
  font-weight: bold;
  cursor: pointer;
}
</style>
