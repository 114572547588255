<template>
  <div>
    <div
      class="d-flex align-center pointer chiller-usage-type-label"
      :class="{
        'error--text': hasError && isCollapsed,
        'grey--text': fieldsDisabled
      }"
      @click="onCollapseClick"
    >
      <v-simple-checkbox
        :value="isUsed"
        :disabled="disabled"
        @input="isChecked = !!$event"
        @click.stop=""
        dense
        hide-details
      ></v-simple-checkbox>
      <h3>{{ label }}</h3>
      <v-icon>{{
        isCollapsed ? "mdi-chevron-right" : "mdi-chevron-down"
      }}</v-icon>
      <v-spacer />
    </div>

    <div v-show="!isCollapsed" class="chiller-usage-type-input-wrapper">
      <v-autocomplete
        v-model="usageProfile"
        :error-messages="filteredFieldErrors.usage_profile"
        :items="usageProfiles"
        :disabled="fieldsDisabled"
        @input="didTouchField('usage_profile')"
        dense
        hide-details="auto"
        label="Nutzungsprofil"
        item-text="name"
        item-value="id"
        :rules="fieldErrors.usage_profile"
        validate-on-blur
      >
        <v-tooltip v-if="hint.usage_profile" slot="append-outer" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ hint.usage_profile }}</span>
        </v-tooltip>
      </v-autocomplete>

      <v-text-field
        v-model.number="usageShare"
        :error-messages="filteredFieldErrors.usage_share"
        :disabled="fieldsDisabled"
        @input="didTouchField('usage_share')"
        dense
        hide-details="auto"
        label="Anteil der Nutzungsart"
        suffix="%"
        :hint="hint.usage_share"
        :rules="fieldErrors.usage_share"
        validate-on-blur
      />

      <v-autocomplete
        v-model="operationMode"
        :error-messages="filteredFieldErrors.operation_mode"
        :items="operationModes"
        :disabled="fieldsDisabled || isAC"
        @input="didTouchField('operation_mode')"
        dense
        hide-details="auto"
        label="Betriebsart"
        :rules="fieldErrors.operation_mode"
        validate-on-blur
      >
        <v-tooltip v-if="hint.operation_mode" slot="append-outer" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ hint.operation_mode }}</span>
        </v-tooltip>
      </v-autocomplete>

      <v-autocomplete
        v-model="humidityRequirement"
        :error-messages="filteredFieldErrors.humidity_requirement"
        :items="humidityRequirements"
        :disabled="fieldsDisabled"
        @input="didTouchField('humidity_requirement')"
        v-if="isAHU"
        dense
        hide-details="auto"
        label="Feuchteanforderung"
        no-data-text="Betriebsart wählen"
        :rules="fieldErrors.humidity_requirement"
        validate-on-blur
      >
        <v-tooltip v-if="hint.humidity_requirement" slot="append-outer" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ hint.humidity_requirement }}</span>
        </v-tooltip>
      </v-autocomplete>

      <v-autocomplete
        v-model="heatRecovery"
        :error-messages="filteredFieldErrors.heat_recovery"
        :items="heatRecoveries"
        :disabled="fieldsDisabled"
        @input="didTouchField('heat_recovery')"
        v-if="isAHU"
        dense
        hide-details="auto"
        label="Wärmerückgewinnung"
        no-data-text="Feuchteanforderung wählen"
        :rules="fieldErrors.heat_recovery"
        validate-on-blur
      >
        <v-tooltip v-if="hint.heat_recovery" slot="append-outer" bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ hint.heat_recovery }}</span>
        </v-tooltip>
      </v-autocomplete>

      <v-autocomplete
        v-model="temperatureFreeCooling"
        :error-messages="filteredFieldErrors.temperature_free_cooling"
        :items="temperaturesFreeCooling"
        :disabled="fieldsDisabled"
        @input="didTouchField('temperature_free_cooling')"
        v-if="freeCooling"
        dense
        hide-details="auto"
        label="Temperaturniveau freie Kühlung"
        no-data-text="Nutzungsprofil wählen"
        suffix="°C"
        :rules="fieldErrors.temperature_free_cooling"
        validate-on-blur
      >
        <v-tooltip
          v-if="hint.temperature_free_cooling"
          slot="append-outer"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>
          <span>{{ hint.temperature_free_cooling }}</span>
        </v-tooltip>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { djangoChoicesToVuetify } from "@/utils";
import { cacheAdapter } from "@/cache";

export default {
  name: "ChillerUsageType",
  props: {
    value: { required: true },
    fieldErrors: { required: true },
    chillerType: { required: true },
    freeCooling: { required: true },
    knownSeer: { required: true },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    initiallyChecked: { type: Boolean, default: false },
    hint: { required: true }
  },

  data() {
    return {
      usageProfile: null,
      usageShare: null,
      operationMode: null,
      humidityRequirement: null,
      heatRecovery: null,
      temperatureFreeCooling: null,

      usageProfiles: [],
      operationModes: [],
      humidityRequirements: [],
      heatRecoveries: [],
      temperaturesFreeCooling: [],

      isChecked: false,
      isCollapsed: false,
      touchedFields: []
    };
  },

  beforeMount() {
    this.fetchUsageProfiles();
  },

  mounted() {
    if (this.initiallyChecked) {
      this.isChecked = true;
    } else {
      this.isCollapsed = true;
    }
  },

  computed: {
    isUsed() {
      return !this.disabled && this.isChecked;
    },
    data: {
      get() {
        if (!this.isUsed) {
          return null;
        }

        if (this.chillerType === 2) {
          return null;
        }
        if (this.chillerType === 3 && this.knownSeer) {
          return null;
        }

        let result = {
          usage_profile: this.usageProfile,
          usage_share: this.usageShare
        };
        if (this.isAHU) {
          result.heat_recovery = this.heatRecovery;
          result.humidity_requirement = this.humidityRequirement;
        }
        if (!this.isAC) {
          result.operation_mode = this.operationMode;
        }
        if (this.isAC && !this.knownSeer) {
          result.operation_mode = 1;
        }
        if (this.freeCooling) {
          result.temperature_free_cooling = this.temperatureFreeCooling;
        }
        return result;
      },
      set(value) {
        if (!value) {
          this.isChecked = false;
          return;
        }
        this.isChecked = true;
        this.usageProfile = value.usage_profile;
        this.usageShare = value.usage_share;
        this.operationMode = value.operation_mode;
        this.humidityRequirement = value.humidity_requirement;
        this.heatRecovery = value.heat_recovery;
        this.temperatureFreeCooling = value.temperature_free_cooling;
      }
    },
    filteredFieldErrors() {
      let fieldErrors = this.fieldErrors ?? {};
      let result = {};
      for (let key of this.touchedFields) {
        result[key] = fieldErrors[key];
      }
      return result;
    },
    hasError() {
      return Object.values(this.fieldErrors).length > 0 && this.isUsed;
    },
    isAHU() {
      return this.operationMode === 2;
    },
    isAC() {
      return this.chillerType === 3;
    },
    fieldsDisabled() {
      return !this.isUsed || this.disabled;
    }
  },

  watch: {
    value: {
      handler(value) {
        if (JSON.stringify(value) !== JSON.stringify(this.data)) {
          this.data = value;
        } else if (!value) {
          this.data = null;
        }
      },
      immediate: true
    },
    data(data) {
      this.$emit("input", data);
    },
    operationMode: {
      handler() {
        if (this.operationMode == 2) {
          this.fetchHumidityRequirements();
        }
      },
      immediate: true
    },
    humidityRequirement: {
      handler() {
        if (this.humidityRequirement != null) {
          this.fetchHeatRecoveries();
        }
      },
      immediate: true
    },
    chillerType: {
      handler() {
        if (this.chillerType != null && this.chillerType !== 2) {
          this.fetchOperationModes();
        }
      },
      immediate: true
    },
    usageProfile: {
      handler: "fetchTemperaturesFreeCooling",
      immediate: true
    }
  },

  methods: {
    didTouchField(field, instantly) {
      if (instantly) {
        this.touchedFields.push(field);
        this.touchedFields = Array.from(new Set(this.touchedFields));
      } else {
        setTimeout(() => {
          this.didTouchField(field, true);
        }, 1000);
      }
    },

    onCollapseClick() {
      this.isCollapsed = !this.isCollapsed;
      if (!this.isCollapsed) {
        for (let field of Object.keys(this.fieldErrors)) {
          this.didTouchField(field, true);
        }
      }
    },

    fetchUsageProfiles() {
      this.$http
        .get("/api/v1/methods/chiller/usage-profiles/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.usageProfiles = response.data;
        })
        .catch(() => {});
    },

    fetchOperationModes() {
      this.$http
        .get("/api/v1/methods/chiller/operation-modes/", {
          params: { chiller_type: this.chillerType },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.operationModes = djangoChoicesToVuetify(response.data);
          if (this.operationModes.length === 1) {
            this.operationMode = this.operationModes[0].value;
          }
        })
        .catch(() => {});
    },

    fetchHumidityRequirements() {
      this.$http
        .get("/api/v1/methods/chiller/humidity-requirements/", {
          params: { operation_mode: this.operationMode },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.humidityRequirements = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchHeatRecoveries() {
      this.$http
        .get("/api/v1/methods/chiller/heat-recoveries/", {
          params: { humidity_requirement: this.humidityRequirement },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.heatRecoveries = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    },

    fetchTemperaturesFreeCooling() {
      this.$http
        .get("/api/v1/methods/chiller/temperatures-free-cooling/", {
          params: { usage_profile: this.usageProfile },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.temperaturesFreeCooling = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.chiller-usage-type-label {
  user-select: none;
  margin-bottom: 14px;
}
.chiller-usage-type-input-wrapper {
  padding: 0 0 18px 24px;
}
.chiller-usage-type-input-wrapper .v-input {
  margin-top: 20px;
}
</style>
