<template>
  <v-expansion-panels accordion hover>
    <v-expansion-panel :disabled="!actvalid">
      <v-expansion-panel-header>
        <div v-show="actvalid" class="heading">
          Voraussichtliche Ergebnisse
        </div>
        <div v-show="!actvalid" class="heading">
          Die Vorschau ist nur bei gültigen Parametern verfügbar!
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="4" lg="4">
            <Transition>
              <Previewpercentage
                v-show="actvalid"
                :actionData="actdata"
                :update="actvalid"
              />
            </Transition>
          </v-col>

          <v-col cols="4" lg="4">
            <Transition>
              <Preview
                v-show="actvalid"
                :actionData="actdata"
                :update="actvalid"
              />
            </Transition>
          </v-col>

          <v-col cols="4" lg="4">
            <v-card class="padding mt-2" v-show="actvalid">
              <v-card-text>
                <h4>Hinweis:</h4>
                Die Diagramme stellen Endenergien dar.
                Einsparungen werden positiv dargestellt.
                Negative Werte stellen daher Mehrverbräuche dar.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import "@/assets/StyleMethodHelp.css";
import Preview from "./Preview.vue";
import Previewpercentage from "./Previewpercentage.vue";

export default {
  name: "Previewcard",
  components: {
    Preview,
    Previewpercentage
  },
  props: {
    actdata: null,
    actvalid: null
  }
};
</script>

<style scoped>
.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  /* max-width: 980px; */
  margin: 0 auto;
  padding: 45px;
  max-width: 80ch;
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
}

.v-enter-active,
.v-leave-active {
  transition: 2s;
}

.v-enter-from,
.v-leave-to {
  opacity: 100;
}
.padding {
  padding: 15px;
}
div.heading {
  color: black;
  font-size: 1.25rem;
}
</style>
