<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="amount"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.amount
        "
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="volumeFlow"
      :error-messages="fieldErrors.volumeFlow"
      label="Luftmenge"
      suffix="m³/h"
      :hint="hint.volume_flow"
      :rules="validationErrors.volume_flow"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.volume_flow
        "
        @click="getReferenceParameter(`volume_flow`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="totalPressure"
      :error-messages="fieldErrors.totalPressure"
      label="Gesamtdruck"
      suffix="Pa"
      :hint="hint.total_pressure"
      :rules="validationErrors.total_pressure"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.total_pressure
        "
        @click="getReferenceParameter(`total_pressure`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-model.number="uptime"
      :error-messages="fieldErrors.uptime"
      label="Jährliche Betriebsstunden"
      suffix="h"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.uptime
        "
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-if="reference"
      v-model.number="yearBuilt"
      :error-messages="fieldErrors.yearBuilt"
      label="Baujahr"
      :hint="hint.year_built"
      :rules="validationErrors.year_built"
      validate-on-blur
    />
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";

export default {
  name: "Fan1Form",
  extends: BaseForm,

  data() {
    return {
      amount: null,
      volumeFlow: null,
      totalPressure: null,
      yearBuilt: null,
      uptime: null,

      fields: {
        amount: "amount",
        volumeFlow: "volume_flow",
        totalPressure: "total_pressure",
        yearBuilt: "year_built",
        uptime: "uptime"
      }
    };
  }
};
</script>
