<template>
  <div>
    <h1>Trockenläuferpumpe 1</h1>
    <img src="@/assets/help/drypump/drypump.png" alt="Trockenläuferpumpe" />
    <figcaption>Quelle: Wilo</figcaption>
    <p>
      Elektromotoren sind in Europa die wichtigsten Stromverbraucher: Sie sind
      für rund 70 % des industriellen Stromverbrauchs und ein Drittel des
      Stromverbrauchs im Dienstleistungssektor verantwortlich. Fast 20 % des
      weltweiten Stromverbrauchs von Motoren entfallen dabei auf Pumpen. Eine
      Reduzierung des Energieverbrauchs kommt nicht nur der Umwelt – durch
      Ressourcenschonung und weniger klimaschädliches CO2 – zugute, sondern
      auch der Nachhaltigkeitsbilanz der Unternehmen. Denn Stromkosten machen
      den größten Teil der Lebenszykluskosten einer Pumpe aus – bis zu 85 %.
      Das weltweite Einsparpotenzial allein durch die Anwendung moderne
      Pumpentechnik beläuft sich auf bis zu 246 TWh/a, das entspricht einer
      Größenordnung von 24 Atomkraftwerken.
    </p>

    <h3>Optimierungsmöglichkeiten</h3>
    <p>
      Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten eines
      elektrischen Antriebssystems:
    </p>
    <table>
      <thead>
        <tr>
          <th>Kategorie</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Motor</td>
          <td>Austausch der Pumpe durch neue energieeffiziente Pumpe</td>
        </tr>
        <tr>
          <td>Dimensionierung</td>
          <td>Anpassen der Pumpengröße an den tatsächlichen Bedarf</td>
        </tr>
        <tr>
          <td>Regelung / Steuerung</td>
          <td>
            Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
            VSD)
          </td>
        </tr>
        <tr>
          <td>Betriebszeit</td>
          <td>
            Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
          </td>
        </tr>
        <tr>
          <td>Verbraucher</td>
          <td>Systemoptimierung zur Reduzierung des Bedarfs</td>
        </tr>
      </tbody>
    </table>

    <h3>Energieeffizienzklassen für Pumpen</h3>
    <p>
      Die Anforderung an den Wirkungsgrad von Trockenläuferpumpen ist in den
      vergangenen Jahren erheblich gestiegen. Dabei wird nicht nur der Antrieb
      durch den elektrischen Motor, sondern auch der hydraulische Wirkungsgrad
      der Pumpe bewertet.
    </p>

    <h4>Anforderungen an den Antrieb</h4>
    <p>
      Die Norm IEC 60034-30:2008 definiert die Wirkungsgradklassen für
      Drehstrom-Niederspannungsmotoren im Leistungsbereich von 0,75 kW bis 375
      kW. Die Abkürzung IE steht für International Efficiency
    </p>
    <table>
      <thead>
        <tr>
          <th>Klasse</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>IE1</td>
          <td>Standard Wirkungsgrad (vergleichbar EFF2)</td>
        </tr>
        <tr>
          <td>IE2</td>
          <td>Hoher Wirkungsgrad (vergleichbar EFF1)</td>
        </tr>
        <tr>
          <td>IE3</td>
          <td>Premium Wirkungsgrad</td>
        </tr>
        <tr>
          <td>IE4</td>
          <td>Super Premium Efficiency</td>
        </tr>
      </tbody>
    </table>

    <img
      src="@/assets/help/electricdrive/power-eff.png"
      alt="Effizienz über Nennleitung"
    />

    <p>Fristen:</p>
    <ul>
      <li>
        Seit Juli 2021 müssen Dreiphasenmotoren mit einer Nennausgangsleistung
        zwischen 0,75 kW und höchstens 1000 kW das Effizienzniveau IE3
        aufweisen.
      </li>
      <li>
        Ab Juli 2023 ist für Motoren mit einer Leistung zwischen 75 kW und 200
        kW das Effizienzniveau IE4 verpflichtend.
      </li>
    </ul>

    <h4>Anforderung an den hydraulischen Wirkungsgrad</h4>
    <p>
      Der hydraulische Wirkungsgrad wird am Mindesteffizienzindex MEI gemessen.
      (gem. Verordnung (EU) 547/2012).
    </p>
    <p>Seit dem 01.01.2015 wird ein MEI &gt; 0,4 gefordert.</p>

    <h3>Neue Pumpentechnologie</h3>
    <span><b>Wilo-Stratos GIGA</b></span>
    <p>
      Die vorausgewählte Trockenläuferpumpe für den Austausch ist die
      Wilo-Stratos GIGA. Die Pumpen der Stratos GIGA Reihe von Wilo sind die
      ersten Trockenläuferpumpen in EC-Motorentechnologie bis zu einer Leistung
      von ca 7,5 kW. Der Antrieb erfolgt über Hocheffizienz EC-Motoren, die
      heute schon oberhalb der höchsten Güteklasse von Elektromotoren liegen,
      welche ab 2017 Vorschrift ist.
    </p>
    <p>
      Ebenfalls liegen die hydraulischen Eigenschaften der Trockenläuferpumpen
      der Stratos GIGA Reihe mit einem Mindesteffizienzindex (MEI) ≥ 0,7 (gemäß
      ErP Direktive 2009/125/EC [Commission Regulation (EU) 547/2012]) heute
      schon oberhalb der ab 2015 geltenden Vorschriften.
    </p>
    <p>
      Durch eine integrierte elektronische Leistungsanpassung mit einfacher
      Bedienung erreichen die Pumpen einen bis zu dreimal größeren Regelbereich
      als herkömmliche elektronisch geregelte Pumpen und mittels einer
      Kommunikationsschnittstelle für alle gängigen Protokolle (Modbus, BACnet,
      CAN, PLR, LON) können die Pumpen direkt an die Gebäudeautomation
      angebunden werden.
    </p>

    <img
      src="@/assets/help/drypump/betriebsbereiche-stratos-giga.png"
      alt="Betriebsbereiche Stratos GIGA"
    />
    <figcaption>
      Betriebsbereiche verschiedener Stratos GIGA Trockenläuferpumpen (Quelle:
      Wilo)
    </figcaption>

    <img
      src="@/assets/help/drypump/anforderungen-wirkungsgrad.png"
      alt="Anforderungen an den elektrischen Wirkungsgrad"
    />
    <figcaption>
      Erfüllung der Anforderungen an den elektrischen Wirkungsgrad des Abtriebes
      (Quelle: Wilo)
    </figcaption>

    <h2>Hinweise zur Durchführung</h2>
    <p>
      Die Methode 1 ist auf die Durchführung von Potentialanalysen
      zugeschnitten. Ziel ist es, mit geringem Aufwand für Begehung und
      Datenaufnahme einen Überblick über die vorhandenen
      Energieeinsparpotentiale einer Liegenschaft zu erhalten. In der Auswertung
      der Potentialanalyse werden die Energieeinsparpotentiale mit einem
      Toleranzbereich von +- 15% angegeben.
    </p>
    <p>
      Werden vom Kunden bereits im Rahmen der Potentialanalyse detailliertere
      Angaben geliefert, so kann auch Methode 2 für die Durchführung der
      Potentialanalyse verwendet werden.
    </p>

    <parameter-help method="drypump1" />
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "DryPump1Help",
  components: {
    ParameterHelp,
  },
};
</script>

<style scoped>
</style>