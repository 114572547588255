<template>
  <div>
    <v-card class="chart-body mt-2">
      <bar-chart :chart-data="datacollection" :options="options" />
    </v-card>
  </div>
</template>

<script>
import BarChart from "./BarChart.vue";
import ChartDataLabels from "chartjs-plugin-datalabels"; //globaler import
Chart.plugins.register(ChartDataLabels); //globaler import

export default {
  name: "Previewpercentage",
  components: { BarChart },
  props: ["actionData", "update"],
  data() {
    return {
      datacollection: {
        labels: [],
        datasets: []
      },
      options: {
        plugins: {
          datalabels: {
            formatter: function(value) {
              return Math.round(value).toLocaleString();
            },
            color: "white",
            // display: false,
            backgroundColor: "black",
            borderRadius: 4,
            font: {
              weight: "bold"
            },
            offset: 2,
            padding: 2
          }
        },
        responsive: true,
        title: {
          display: true,
          text: "Differenz relativ"
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "%"
              },
              display: true,
              stacked: true,
              ticks: {
                beginAtZero: true
              }
            }
          ],
          xAxes: [
            {
              stacked: true
            }
          ]
        },
        layout: {
          autopadding: true
        }
      },
      plotErrors: null,

      colors: {
        1: "#F7BA0B",
        2: "#D4652F",
        3: "#D4652F",
        4: "#904684",
        5: "#7B5141",
        6: "#9C6B30",
        7: "#8D1D2C",
        10: "#EEC900",
        11: "#A02128",
        12: "#2E5978",
        13: "#48A43F"
      },
      defaultColor: "#9EA0A1",

      colorsb: {
        1: "#9EA0A1",
        2: "#9EA0A1",
        3: "#9EA0A1",
        4: "#9EA0A1",
        5: "#9EA0A1",
        6: "#9EA0A1",
        7: "#9EA0A1",
        10: "#9EA0A1",
        11: "#9EA0A1",
        12: "#9EA0A1",
        13: "#9EA0A1"
      },
      defaultColor: "#9EA0A1"
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.$http
        .post("/api/v1/actions/preview/", this.actionData)
        .then((response) => {
          this.prepare(response.data);
        })
        .catch((error) => {
          this.plotErrors = error.response.data;
        });
    },
    prepare(data) {
      let ids = data.delta_rel.map((f) => f.id);
      let colors = [];
      for (const i of ids) {
        colors.push(this.colors[i]);
      }
      if (data) {
        this.datacollection = {
          labels: data.delta_rel.map((f) => f.name),
          datasets: [
            {
              label: "",
              backgroundColor: colors,
              data: data.delta_rel.map((f) => f.quantity),
              barPercentage: 0.7,
              datalabels: {
                align: "center",
                anchor: "center"
              }
            }
          ]
        };
      }
    },
  },
  watch: {
    update(value) {
      // timer -> solved with Transition in Previewbar
      if (value) {
        this.loadData();
      }
    }
  }
};
</script>

<style scoped>
.chart-body {
  box-sizing: border-box;
  padding: 15px;
}
</style>
