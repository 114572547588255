<template>
  <v-navigation-drawer 
    v-model="drawer" 
    :mini-variant.sync="mini" 
    clipped
    permanent 
    floating
    app 
    class="secondary"
    text
    dark
    touchless>

      <v-list-item class="px-2" rounded>

        <v-btn class="mr-3" v-if="!mini" icon @click.stop="toggleMini" aria-label="close">
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <v-btn class="mr-3" v-if="mini" icon @click.stop="toggleMini" aria-label="close" title="Navigation öffnen">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>

        <v-list-item-title class="d-flex justify-content-center" >Navigation</v-list-item-title>

        <v-btn icon @click.stop="toggleMini" aria-label="open" title="Navigation minimieren">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

      </v-list-item>

      <v-divider></v-divider>

      <v-list 
        nav
        v-if="title" 
        dense>
        <v-list-item 
          exact
          v-for="item in items" 
          :key="item.title" 
          router 
          :to="{name: item.route}">

          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          
        </v-list-item>
      </v-list>
      <v-list 
        nav
        v-if="!title" 
        dense>
        <v-list-item 
          exact
          v-for="item in itemsNewProject" 
          :key="item.title" 
          router 
          :to="{name: item.route}">

          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          
        </v-list-item>
      </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      drawer: false,
      itemsNewProject: [
	      { title: "Home", icon: "mdi-home", route: "Home" },
        { title: "Projekte", icon: "mdi-format-list-bulleted", route: "ProjectList" },
        // { title: 'Neues Projekt', icon: 'mdi-database-plus', route: 'NewProject' }, Error 400
      ],
      items: [
	      { title: "Home", icon: "mdi-home", route: "Home" },
        { title: "Projekte", icon: "mdi-format-list-bulleted", route: "ProjectList" },
        { title: "Projektdetails", icon: "mdi-rename-box", route: "ProjectDetails" },
        { title: "Ma\ßnahmen", icon: "mdi-database", route: "ProjectDashboard" },
        { title: "Neue Ma\ßnahme", icon: "mdi-file-plus", route: "NewAction" },
        { title: "Analyse", icon: "mdi-speedometer", route: "ProjectCalc" },
        // { title: 'Neues Projekt', icon: 'mdi-database-plus', route: 'NewProject' }, Error 400
      ],
    }
  },
  computed: {
    title() {
        return this.$store.state.title
    },
    project() {
      return this.$store.state.currentProject;
    },
    mini: {
      get() {
        return this.$store.getters.getNavigationMini
      },
      set(mini) {
        this.$store
        .dispatch("set_navigationMini", !mini)
      }
    }
  },
  methods: {
    toggleMini() {
      this.$store
        .dispatch("set_navigationMini", !this.mini)
    }
  }
}
</script>