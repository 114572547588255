<template>
  <v-menu v-model="open" :close-on-content-click="false" :nudge-left="100">
    <template #activator="{ on }">
      <v-btn
        v-on="on"
        color="red"
        text
        :class="activatorClass"
        :icon="activatorIcon"
        title="Löschen"
        ><slot>Löschen</slot></v-btn
      >
    </template>
    <div class="white">
      <v-list-item class="px-2">
        <v-btn text block @click="open = false">Abbrechen</v-btn>
      </v-list-item>

      <v-list-item class="px-2">
        <v-btn block @click="launch" color="red" dark>Löschen</v-btn>
      </v-list-item>
    </div>
  </v-menu>
</template>
<script>
export default {
  name: "DeleteMissileSwitch",
  props: {
    activatorClass: null,
    activatorIcon: null,
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    launch() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped></style>
