<template>
  <v-form @submit.prevent="" ref="form">
    <v-autocomplete
      v-model="zone"
      :error-messages="fieldErrors.zone"
      label="Zone"
      :items="zones"
      item-text="name"
      item-value="id"
      :rules="validationErrors.zone"
      validate-on-blur
    >
      <v-tooltip v-if="hint.zone" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.zone }}</span>
      </v-tooltip>
    </v-autocomplete>

    <v-text-field
      v-model.number="area"
      type="number"
      :error-messages="fieldErrors.area"
      label="Fläche"
      suffix="m²"
      :hint="hint.area"
      :rules="validationErrors.area"
      validate-on-blur
    />
    <v-autocomplete
      v-model="change"
      :error-messages="fieldErrors.change"
      label="Austausch"
      :items="changeTypes"
      item-text="name"
      item-value="id"
      :disabled="!zone"
      :rules="validationErrors.change"
      validate-on-blur
    >
      <v-tooltip v-if="hint.change" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.change }}</span>
      </v-tooltip>
    </v-autocomplete>
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { cacheAdapter } from "@/cache";

export default {
  name: "Lighting2FormShared",
  extends: BaseForm,

  data() {
    return {
      zone: null,
      area: null,
      change: null,

      fields: {
        zone: "zone",
        area: "area",
        change: "change"
      },

      zones: [],
      changeTypes: [
        { id: "sys", name: "Leuchtensystem" },
        { id: "bulb", name: "Leuchtmittel" }
      ]
    };
  },

  beforeMount() {
    this.fetchBuildingTypes();
  },

  methods: {
    fetchBuildingTypes() {
      this.$http
        .get("/api/v1/methods/lighting2/zone/", {
          params: {},
          adapter: cacheAdapter
        })
        .then((response) => {
          this.zones = response.data;
        })
        .catch(() => {
          // TODO: Error handling
        });
    }
  }
};
</script>

<style scoped></style>
