<template>
  <tr>
    <td>{{ fuel.name }}</td>
    <td>
      <v-text-field
        label=""
        v-model="cost"
        outlined
        dense
        class="mt-6"
        :error-messages="costErrors"
      />
    </td>
    <td>
      <v-text-field
        label=""
        v-model="consumption"
        outlined
        dense
        class="mt-6"
        :error-messages="consumptionErrors"
      />
    </td>
    <td>{{ chosenEnergyPrice }}
      <v-tooltip v-if="hint" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint }}</span>
      </v-tooltip>
    </td>
  </tr>
</template>

<script>
export default {
  name: "EnergyStatsYearFuel",

  props: {
    value: { type: Object, required: true },
    fuel: { type: Object, required: true },
    errors: { type: Object, required: true },
    hint: { type: String }
  },

  data() {
    return {
      cost: 0,
      consumption: 0
    };
  },
  computed: {
    data: {
      get() {
        return { cost: this.cost, consumption: this.consumption };
      },
      set(data) {
        let { cost, consumption } = data;
        this.cost = cost;
        this.consumption = consumption;
      }
    },
    chosenEnergyPrice() {
      const total =
        this.cost && this.consumption
          ? this.cost / (this.consumption) || 0
          : 0;
      return parseFloat(total).toFixed(2);
    },
    costErrors() {
      return this.errors?.cost;
    },
    consumptionErrors() {
      return this.errors?.consumption;
    }
  },
  watch: {
    data: {
      handler(data) {
        this.$emit("input", data);
      },
      immediate: true
    },
    value: {
      handler(value) {
        this.data = value;
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
