<template>
  <div>
    <hr>
    <h2>Parameter</h2>
    <div v-for="parameter in parameters" :key="parameter.key">
      <h4>{{ parameter.name }}</h4>
      <p class="ml-3">{{ parameter.help || parameter.name }}</p>
    </div>
  </div>
</template>

<script>
import {cacheAdapter} from "@/cache";

export default {
  name: "ParameterHelp",

  data() {
    return {
      parameters: []
    }
  },

  props: {
    method: String
  },

  beforeMount () {
    this.fetchParameters()
  },

  methods: {
    fetchParameters () {
      this.$http.get(`/api/v1/methods/${this.method}/help/`, {adapter: cacheAdapter})
          .then(response => {
            this.parameters = response.data
          }).catch(() => {
            this.parameters = []
      })
    }
  }
}
</script>