<template>
  <v-form @submit.prevent="" ref="form">
    <v-autocomplete
      v-model="buildingType"
      :error-messages="fieldErrors.buildingType"
      label="Gebäudetyp"
      :items="buildingTypes"
      item-text="name"
      item-value="id"
      :rules="validationErrors.building_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.building_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.building_type }}</span>
      </v-tooltip>
    </v-autocomplete>

    <v-text-field
      :disabled="!buildingType"
      v-model.number="bgf"
      type="number"
      :error-messages="fieldErrors.bgf"
      label="Bruttogrundfläche"
      suffix="m²"
      :hint="hint.bgf"
      :rules="validationErrors.bgf"
      validate-on-blur
    />
    <v-text-field
      :disabled="!bgf"
      v-model.number="partMainArea"
      type="number"
      :error-messages="fieldErrors.partMainArea"
      label="Anteil Hauptfläche"
      suffix="%"
      :hint="hint.part_main_area"
      :rules="validationErrors.part_main_area"
      validate-on-blur
    />
    <v-text-field
      :disabled="!bgf"
      v-model.number="partAuxArea"
      type="number"
      :error-messages="fieldErrors.partAuxArea"
      label="Anteil Nebenfläche"
      suffix="%"
      :hint="hint.part_aux_area"
      :rules="validationErrors.part_aux_area"
      validate-on-blur
    />
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";

export default {
  name: "Lighting1FormShared",
  extends: BaseForm,

  data() {
    return {
      buildingType: null,
      bgf: null,
      partMainArea: null,
      partAuxArea: null,

      fields: {
        buildingType: "building_type",
        bgf: "bgf",
        partMainArea: "part_main_area",
        partAuxArea: "part_aux_area"
      },

      buildingTypes: []
    };
  },

  beforeMount() {
    this.fetchBuildingTypes();
  },

  methods: {
    fetchBuildingTypes() {
      this.$http
        .get("/api/v1/methods/lighting1/building_type/", {
          params: {
            planned: this.planned || null,
            reference: this.reference || null
          }
        })
        .then((response) => {
          this.buildingTypes = response.data;
        })
        .catch(() => {
          // TODO: Error handling
        });
    }
  }
};
</script>

<style scoped></style>
