<template>
  <div>
    <h1>Elektrische Antriebe 2</h1>
    <img
      src="@/assets/help/electricdrive/electricdrive.png"
      alt="Elektromotor"
    />
    <p>
      Elektromotoren sind in Europa die wichtigsten Stromverbraucher: Sie sind
      für rund 70 % des industriellen Stromverbrauchs und ein Drittel des
      Stromverbrauchs im Dienstleistungssektor verantwortlich. Die Kosten für
      den Stromverbrauch machen bis zu 96 % der Kosten eines Motors über die
      gesamte Lebensdauer aus. Der Rest teilt sich zu 2,5 % auf den Kaufpreis
      und zu 1,5 % auf die Wartungskosten auf.
    </p>

    <h3>Optimierungsmöglichkeiten</h3>
    <p>
      Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten eines
      elektrischen Antriebssystems:
    </p>
    <table>
      <thead>
        <tr>
          <th>Kategorie</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Motor</td>
          <td>Austausch des Motors durch neuen energieeffizienten Motor IE3</td>
        </tr>
        <tr>
          <td>Dimensionierung</td>
          <td>Anpassen der Motorgröße an den tatsächlichen Bedarf</td>
        </tr>
        <tr>
          <td>Regelung / Steuerung</td>
          <td>
            Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
            VSD)
          </td>
        </tr>
        <tr>
          <td>Betriebszeit</td>
          <td>
            Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
          </td>
        </tr>
        <tr>
          <td>Verbraucher</td>
          <td>Mechanische Systemoptimierung zur Reduzierung des Bedarfs</td>
        </tr>
      </tbody>
    </table>

    <img
      src="@/assets/help/electricdrive/einsparpotenziale.png"
      alt="Einsparpotenziale"
    />

    <h3>Motor - Wirkungsgradklassen EFF und IE</h3>
    <h4>Bisherige Wirkungsgradklassen (EFF)</h4>
    <p>
      Im Rahmen der freiwilligen Selbstverpflichtung zwischen dem europäischen
      Sektor-Komitee für elektrische Antriebe CEMEP und der Europäischen
      Kommission wurden im Jahr 1998 drei Wirkungsgradklassen im
      Leistungsbereich 1,1 - 90 kW definiert:
    </p>

    <table>
      <thead>
        <tr>
          <th>Klasse</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>EFF3</td>
          <td>
            Motoren mit niedrigem Wirkungsgrad (meist eingesetzt bis ca. Jahr
            2000)
          </td>
        </tr>
        <tr>
          <td>EFF2</td>
          <td>Motoren mit verbessertem Wirkungsgrad</td>
        </tr>
        <tr>
          <td>EFF1</td>
          <td>Motoren mit erhöhtem Wirkungsgrad</td>
        </tr>
      </tbody>
    </table>
    <img
      src="@/assets/help/electricdrive/marktanteile-eff.png"
      alt="Marktanteile von Eff-Motoren"
    />
    <h4>Neue Wirkungsgradklassen (IE) ab 2008</h4>
    <p>
      Die Norm IEC 60034-30:2008 definiert die Wirkungsgradklassen für
      Drehstrom-Niederspannungsmotoren im Leistungsbereich von 0,75 kW bis 375
      kW. Die Abkürzung IE steht für International Efficiency
    </p>
    <table>
      <thead>
        <tr>
          <th>Klasse</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>IE1</td>
          <td>Standard Wirkungsgrad (vergleichbar EFF2)</td>
        </tr>
        <tr>
          <td>IE2</td>
          <td>Hoher Wirkungsgrad (vergleichbar EFF1)</td>
        </tr>
        <tr>
          <td>IE3</td>
          <td>Premium Wirkungsgrad</td>
        </tr>
        <tr>
          <td>IE4</td>
          <td>Super Premium Efficiency</td>
        </tr>
      </tbody>
    </table>

    <p>Fristen:</p>
    <ul>
      <li>
        Ab 16. Juni 2011 müssen neue Motoren die Energieeffizienzklasse IE2
        einhalten.
      </li>
      <li>
        Ab Januar 2015 ist in der Leistungsklasse 7,5 – 375 kW die
        Energieeffizienzklasse IE3 (oder alternativ IE2-Motor plus
        Frequenzumrichter) und
      </li>
      <li>
        ab Januar 2017 für Motoren mit 0,75 – 375 kW die Energieeffizienzklasse
        IE3 (oder alternativ IE2-Motor plus Frequenzumrichter) einzuhalten.
      </li>
    </ul>

    <img
      src="@/assets/help/electricdrive/power-eff.png"
      alt="Effizienz über Nennleitung"
    />

    <h4>Vergleich mit den bisherigen Wirkungsgradklassen (EFF-Klassen)</h4>
    <p>
      Der Hauptunterschied zwischen den beiden Wirkungsgradklassen (EFF und IE)
      liegt im Verfahren zu deren Ermittlung. Beim direkten Vergleich am selben
      Motor ist davon auszugehen, dass sich der nach den neuen Messverfahren
      ermittelte Wirkungsgrad reduziert. So ist z. B. ein 4-poliger, 11 kW, EFF1
      Motor mit einem Wirkungsgrad von 91,0 % physikalisch identisch mit einem
      IE2 Motor mit einem Wirkungsgrad von 89,8 %.
    </p>
    <img
      src="@/assets/help/electricdrive/eff-klassen.png"
      alt="Effizienz Klassen"
    />

    <h2>Hinweise zur Durchführung</h2>
    <ol type="1">
      <li>
        <b
          >Einsparpotential und Wirtschaftlichkeit mit EARNS-Feinanalyse
          abschätzen anhand der Typenschilddaten</b
        >
        <p>Erste Annahmen hierbai:</p>
        <ul>
          <li>
            Der Bestands-Antrieb läuft im Auslegungspunkt gemäß Typenschild
          </li>
          <li>Der neue Antrieb läuft auf demselben Betriebspunkt</li>
        </ul>
      </li>

      <li>
        <b
          >Tatsächlichen Betriebspunkt durch Messung ermitteln (bei größeren
          Antrieben)</b
        >
        <p>
          Messung der Antriebsleistung, der Drehzahl, und der elektrischen
          Leistungsaufnahme (Wirkleistung inkl. cos phi)
        </p>
      </li>

      <li>
        <b>Bedarf überprüfen und mit aktuellem Betriebspunkt vergleichen</b>
        <p>
          Häufig ist der Bestands-Motor größer ausgelegt als eigentlich benötigt
          wird. Dies kann z.B. daran liegen, dass bei der Auslegung unnötig hohe
          Sicherheitszuschläge berücksichtigt wurden.
        </p>
        <p>
          Mechanische Systemoptimierung und Bedarfsreduzierung zusammen haben am
          Effizienzsteigerungspotential der Anlage einen Anteil von bis zu 60%.
        </p>
        <p>
          Aus diesem Grunde lohnt es sich mindestens folgende Punkte zu prüfen:
        </p>
        <ul>
          <li>
            Übereinstimmung der Betriebszeiten der Anlage mit den Nutzungszeiten
            der Anlage. Läuft der Motor länger als eigentlich erforderlich?
          </li>
          <li>
            Wurden Anlagenteile stillgelegt, ohne dass die Antriebsleistung
            reduziert wurde?
          </li>
          <li>
            Muss der Antrieb immer 100% Leistung bringen, oder kann diese
            entsprechend dem Bedarf heruntergeregelt werden?
          </li>
          <li>Liegen unnötig hohe Leistungsreserven vor?</li>
          <li>
            Eine starre oder flexible Kupplung arbeitet verlustärmer als eine
            indirekte Übertragung durch Riemen. Kann die mechanische Übersetzung
            optimiert werden?
          </li>
        </ul>
      </li>

      <li>
        <b>Auslegungs-Betriebspunkt für den neuen Antrieb festlegen</b>
      </li>
      <li>
        <b
          >Einsparpotential und Wirtschaftlichkeit mit EARNS-Feinanalyse
          abschätzen</b
        >
        <p>
          anhand der gemessenen Daten (Bestandsmotor) und des neuen
          Auslegungspunktes (Neumotor)
        </p>
      </li>

      <li>
        <b
          >Überprüfen der baulichen Einbausituation für die Konzeption des
          Neusystems</b
        >
        <p>
          Manche Motoren höherer Effizienzklassen (IE3-IE4) fallen größer aus
          als Standard-Motoren der Klasse IE1 oder EFF3. Deshalb sollten in der
          Feinanalyse alle Maße wie Achshöhen oder Fußmaße von Bestandsmotor und
          Neumotor überprüft werden. Hierdurch scheiden evtl. bereits gewisse
          Lösungen für das Neusystem aus.
        </p>
      </li>

      <li>
        <b
          >Anhand von Herstellersoftware (Orientalmotor, Winkelmann, Siemens)
          die für den jeweiligen Anwendungsfall (Einbausituation, Betriebspunkt,
          Kosten) optimal geeignetste Systemlösung auswählen</b
        >
      </li>

      <li>
        <b
          >Einsatz einer stufenlosen Drehzahlregelung (VSD Variable Speed Drive)
          bzw. eines Frequenzumrichters (FU) prüfen
        </b>
        <p>
          Der Einsatz eines VSD/FU’s sollte für das neue Antriebssystem geprüft
          werden, da dies hohe Einsparungen bringen kann. Achtung: Nicht immer
          ist dies eine wirtschaftlich sinnvolle Maßnahme. Bei Anwendungen, die
          konstant im Maximallastbereich betrieben werden, ist eine
          Drehzahlregelung oft nicht sinnvoll. Ein FU benötigt ebenfalls
          Energie, besitzt Verluste und muss gewartet werden. Viele Fehler
          können bei der Einstellung der Parameter des FU´s gemacht werden.
          Dadurch kann der Motor ggf. nicht optimal an das Gesamtsystem
          angepasst werden.
        </p>
        <p>
          Kann der Antrieb jedoch oft in Teillast laufen, so rentiert sich ein
          VSD/FU i.d.R. sehr schnell. phi)
        </p>
      </li>

      <li>
        <b
          >Kosten für die gewählte Systemlösung ermitteln, unter
          Berücksichtigung aller relevanten Faktoren wie Aufwand für Demontage,
          Umbau, Einbringung, Anschlussarbeiten, Inbetriebnahme, etc.</b
        >
      </li>

      <li><b>Angebotspreis für die gewählte Systemlösung kalkulieren</b></li>

      <li>
        <b>Finale Einsparhöhe gemäß der gewählten Systemlösung berechnen</b>
      </li>

      <li>
        <b
          >Finale Wirtschaftlichkeit (Einsparung, Rendite, Amortisation) anhand
          des Angebotspreises und der finalen Einsparhöhe ermitteln</b
        >
      </li>
    </ol>

    <parameter-help method="electricdrive2" />

    <h2>Quellen</h2>
    <ul>
      <li>ZVEI-Publikation „Motoren und geregelte Antriebe“</li>
      <li>ZVEI-Publikation „Energiesparen mit elektrischen Antrieben“</li>
      <li>Topmotors.ch</li>
      <li>Motor-Challenge-Programm der EU</li>
      <li>Hochschule Luzern, Technik & Architektur</li>
      <li>EU-Richtlinie 2005/32/EG und IEC 60034-30</li>
      <li>
        Arqum GmbH / Forschungsstelle für Energiewirtschaft e.V. i. A. des
        Bayerischen Staatsminis-teriums für Umwelt und Gesundheit (2010)
      </li>
    </ul>
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "ElectricDrive1Help",
  components: {
    ParameterHelp,
  },
};
</script>

<style scoped>
</style>