import axios from "axios"
import VueAxios from "vue-axios"
import Vue from "vue";


axios.interceptors.request.use(function (config) {
    config.xsrfCookieName = "csrftoken"
    config.xsrfHeaderName = "X-CSRFTOKEN"
    return config;
});

Vue.use(VueAxios, axios)
