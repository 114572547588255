var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-checkbox',{attrs:{"error-messages":_vm.fieldErrors.useManualUptime,"label":"Manuelle Brenndauer","disabled":!_vm.sharedData.zone},model:{value:(_vm.useManualUptime),callback:function ($$v) {_vm.useManualUptime=$$v},expression:"useManualUptime"}},[(_vm.hint.use_manual_uptime !== null)?_c('v-tooltip',{attrs:{"slot":"append","bottom":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.use_manual_uptime))])]):_vm._e(),(!_vm.reference && this.referenceData.use_manual_uptime)?_c('v-btn',{attrs:{"slot":"append","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("use_manual_uptime")}},slot:"append"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.uptime,"label":"Brenndauer","suffix":"h/a","disabled":!_vm.sharedData.zone || !_vm.useManualUptime,"hint":_vm.hint.uptime,"rules":_vm.validationErrors.uptime,"validate-on-blur":""},model:{value:(_vm.uptime),callback:function ($$v) {_vm.uptime=_vm._n($$v)},expression:"uptime"}},[(!_vm.reference && this.referenceData.uptime)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("uptime")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.lampOrLight,"label":"Leuchte","items":_vm.lampsOrLights,"disabled":!_vm.sharedData.change,"rules":_vm.validationErrors.lamp_or_light_qry,"validate-on-blur":""},model:{value:(_vm.lampOrLight),callback:function ($$v) {_vm.lampOrLight=$$v},expression:"lampOrLight"}},[(_vm.hint.lamp_or_light_qry !== null)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.lamp_or_light_qry))])]):_vm._e(),(!_vm.reference && this.referenceData.lamp_or_light_qry)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("lamp_or_light_qry")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-text-field',{attrs:{"type":"number","error-messages":_vm.fieldErrors.amount,"label":"Anzahl","disabled":!_vm.lampOrLight,"hint":_vm.hint.amount,"rules":_vm.validationErrors.amount,"validate-on-blur":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}},[(!_vm.reference && this.referenceData.amount)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("amount")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.power,"label":"Leistung je Lampe","items":_vm.powers,"suffix":"W","disabled":!_vm.amount,"rules":_vm.validationErrors.power_qry,"validate-on-blur":""},model:{value:(_vm.power),callback:function ($$v) {_vm.power=$$v},expression:"power"}},[(_vm.hint.power_qry !== null)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.power_qry))])]):_vm._e(),(!_vm.reference && this.referenceData.power_qry)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("power_qry")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.lightPerLamp,"label":"Lampen je Leuchte","items":_vm.lightPerLamps,"disabled":!_vm.power,"rules":_vm.validationErrors.light_per_lamp,"validate-on-blur":""},model:{value:(_vm.lightPerLamp),callback:function ($$v) {_vm.lightPerLamp=$$v},expression:"lightPerLamp"}},[(_vm.hint.light_per_lamp !== null)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.light_per_lamp))])]):_vm._e(),(!_vm.reference && this.referenceData.light_per_lamp)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("light_per_lamp")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.ballast,"label":"Vorschaltgerät","items":_vm.ballasts,"item-text":"name","item-value":"id","disabled":!_vm.power,"rules":_vm.validationErrors.ballast,"validate-on-blur":""},model:{value:(_vm.ballast),callback:function ($$v) {_vm.ballast=$$v},expression:"ballast"}},[(_vm.hint.ballast !== null)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.ballast))])]):_vm._e(),(!_vm.reference && this.referenceData.ballast)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("ballast")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.ballastPerLamp,"label":"Vorschaltgeräte je Leuchte","items":_vm.ballastPerLamps,"disabled":!_vm.ballast,"rules":_vm.validationErrors.ballast_per_lamp,"validate-on-blur":""},model:{value:(_vm.ballastPerLamp),callback:function ($$v) {_vm.ballastPerLamp=$$v},expression:"ballastPerLamp"}},[(_vm.hint.ballast_per_lamp !== null)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.ballast_per_lamp))])]):_vm._e(),(!_vm.reference && this.referenceData.ballast_per_lamp)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("ballast_per_lamp")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c('v-autocomplete',{attrs:{"error-messages":_vm.fieldErrors.control,"label":"Regelung","items":_vm.controls,"item-text":"name","item-value":"id","disabled":!_vm.ballastPerLamp,"rules":_vm.validationErrors.control,"validate-on-blur":""},model:{value:(_vm.control),callback:function ($$v) {_vm.control=$$v},expression:"control"}},[(_vm.hint.control !== null)?_c('v-tooltip',{attrs:{"slot":"append-outer","bottom":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-information")])]}}],null,false,4275447087)},[_c('span',[_vm._v(_vm._s(_vm.hint.control))])]):_vm._e(),(!_vm.reference && this.referenceData.control)?_c('v-btn',{attrs:{"slot":"append-outer","title":"Referenzwert übernehmen","icon":""},on:{"click":function($event){return _vm.getReferenceParameter("control")}},slot:"append-outer"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }