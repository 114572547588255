<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="constructionYear"
      type="number"
      :error-messages="fieldErrors.constructionYear"
      label="Baujahr"
      :hint="hint.construction_year"
      :rules="validationErrors.construction_year"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.construction_year"
        @click="getReferenceParameter(`construction_year`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="constructionType"
      :error-messages="fieldErrors.constructionType"
      label="Bauart"
      :items="constructionTypes"
      item-text="name"
      item-value="id"
      :rules="validationErrors.construction_type"
      validate-on-blur
    >
      <v-tooltip v-if="hint.construction_type" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.construction_type }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.construction_type"
        @click="getReferenceParameter(`construction_type`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-model.number="nominalPower"
      type="number"
      :error-messages="fieldErrors.nominalPower"
      label="Nennleistung"
      suffix="kW"
      :hint="hint.nominal_power"
      :rules="validationErrors.nominal_power"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.nominal_power"
        @click="getReferenceParameter(`nominal_power`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="fuel"
      :error-messages="fieldErrors.fuel"
      label="Brennstoff"
      :items="fuelTypes"
      :disabled="!constructionType"
      item-text="name"
      item-value="id"
      :rules="validationErrors.fuel"
      validate-on-blur
    >
      <v-tooltip v-if="hint.fuel" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.fuel }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.fuel"
        @click="getReferenceParameter(`fuel`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="tetaVl"
      :error-messages="fieldErrors.tetaVl"
      label="Vorlauftemperatur"
      :items="tetaVlItems"
      suffix="°C"
      :rules="validationErrors.teta_vl"
      validate-on-blur
    >
      <v-tooltip v-if="hint.teta_vl" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.teta_vl }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.teta_vl"
        @click="getReferenceParameter(`teta_vl`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-autocomplete
      v-model="tetaRl"
      :error-messages="fieldErrors.tetaRl"
      label="Rücklauftemperatur"
      :items="tetaRlItems"
      suffix="°C"
      :rules="validationErrors.teta_rl"
      validate-on-blur
    >
      <v-tooltip v-if="hint.teta_rl" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.teta_rl }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.teta_rl"
        @click="getReferenceParameter(`teta_rl`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-checkbox
      v-model="temperatureSlip"
      :error-messages="fieldErrors.temperatureSlip"
      label="Temperaturgleitung"
    >
      <v-tooltip v-if="hint.temperature_slip" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.temperature_slip }}</span>
      </v-tooltip>
      <v-btn
        v-if="!reference && this.referenceData.temperature_slip"
        @click="getReferenceParameter(`temperature_slip`)"
        slot="append"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-checkbox>

    <v-text-field
      v-model.number="uptime"
      type="number"
      :error-messages="fieldErrors.uptime"
      label="Jährliche Betriebsstunden"
      suffix="h"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="!reference && this.referenceData.uptime"
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-text-field
      v-if="reference"
      v-model.number="energyConsumption"
      type="number"
      :error-messages="fieldErrors.energyConsumption"
      label="Energiebedarf"
      suffix="kWh/a"
      :hint="hint.energy_consumption"
      :rules="validationErrors.energy_consumption"
      validate-on-blur
    />
  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { cacheAdapter } from "@/cache";

export default {
  name: "BoilerForm",
  extends: BaseForm,

  data() {
    return {
      constructionYear: null,
      constructionType: null,
      nominalPower: null,
      fuel: null,
      tetaVl: null,
      tetaRl: null,
      temperatureSlip: false,
      uptime: null,
      energyConsumption: null,

      fields: {
        constructionYear: "construction_year",
        constructionType: "construction_type",

        nominalPower: "nominal_power",
        fuel: "fuel",
        tetaVl: "teta_vl",
        tetaRl: "teta_rl",
        temperatureSlip: "temperature_slip",
        uptime: "uptime",
        energyConsumption: "energy_consumption"
      },

      constructionTypes: [],
      tetaVlItems: [28, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90],
      tetaRlItems: [28, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90],
      fuelTypes: []
    };
  },
  computed: {},
  watch: {
    constructionType: {
      handler: "fetchFuelTypes",
      immediate: true
    }
  },
  beforeMount() {
    this.fetchConstructionTypes();
  },

  mounted() {
    if (!this.temperatureSlip) {
      this.temperatureSlip = false;
    }
  },

  methods: {
    fetchConstructionTypes() {
      this.$http
        .get("/api/v1/methods/boiler/types/", {
          params: {
            planned: this.planned || null,
            reference: this.reference || null
          },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.constructionTypes = response.data;
        })
        .catch(() => {
          // TODO: error handling
        });
    },
    fetchFuelTypes() {
      if (!this.constructionType) return;
      this.$http
        .get("/api/v1/methods/boiler/fuel/", {
          params: { construction: this.constructionType },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.fuelTypes = response.data;
          if (this.fuelTypes.length === 1) {
            this.fuel = this.fuelTypes[0].id;
          }
        })
        .catch(() => {
          // TODO: error handling
        });
    }
  }
};
</script>

<style scoped></style>
