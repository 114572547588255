<template>
  <v-simple-table class="mt-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Energieträger</th>
          <th class="text-left">Energiekosten (€/a)</th>
          <th class="text-left">Energieverbrauch gewählt (kWh/a)</th>
          <th class="text-left">Energiepreis gewählt (€/kWh)</th>
        </tr>
      </thead>
      <tbody>
        <EnergyStatsYearFuel
          v-for="(fuel, index) in fuels"
          :key="fuel.id"
          :fuel="fuel"
          v-model="data[fuel.id]"
          :errors="errors[index] || {}"
          :hint="hintFuel[fuel.name]"
        >
        </EnergyStatsYearFuel>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import EnergyStatsYearFuel from "@/components/project/EnergyStatsYearFuel";
export default {
  name: "EnergyStatsYear",
  components: { EnergyStatsYearFuel },
  props: {
    value: { type: Object, required: true },
    fuels: { type: Array, required: true },
    errors: { type: Array, required: true },
    hintFuel: { type: Object }
  },

  data() {
    return {};
  },

  computed: {
    data: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped></style>
