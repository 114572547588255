<template>
  <div>
    <h1>Ventilator 1</h1>
    <img src="@/assets/help/fan/fan-system.png" alt="Ventilatorsystem" />
    <p>
      Für Europa schätzt das Fraunhofer-Institut für Systemtechnik und
      Innovationsforschung ISI, Karlsruhe, den Energieverbrauch für Ventilatoren
      auf weit über 200 Milliarden Kilowattstunden. Das Institut analysierte den
      europäischen Markt für Ventilatoren. Nach den Berechnungen sind bei
      Ventilatoren Energieeinsparungen bis 20 Prozent und mehr erreichbar. Die
      Industrie könnte somit jährlich etwa 2,6 Milliarden Euro an Energiekosten
      sparen.
    </p>

    <h3>EU-Ökodesign-Richtlinie 1253/2014</h3>
    <p>
      Am 26. November 2014 ist die neue EU-Ökodesign-Richtlinie 1253/2014 zur
      Mindesteffizienz von Lüftungsanlagen in Kraft getreten. Demnach müssen
      Lüftungs- und Klimageräte in zwei Schritten ab 1. Januar 2016 und 1.
      Januar 2018 energieeffizienter werden. Betroffen sind alle Geräte, die zur
      Lüftung von Gebäuden dienen und über eine elektrische Anschlussleitung
      über 30 W verfügen. Dabei ist es unerheblich, ob das Gebäude als Wohnraum
      oder für andere Zwecke genutzt wird. Geräte, die ausschließlich in
      explosionsgefährdeten Bereichen genutzt werden, sowie Geräte, die entweder
      in einem Lufttemperaturbereich über 100 °C oder unterhalb von -40 °C
      betrieben werden, sind ausgenommen.
    </p>
    <p>
      Grundsätzlich klassifiziert die Richtlinie drei Gerätekategorien nach dem
      Volumenstrom, an die unterschiedliche Anforderungen gestellt werden:
    </p>
    <ul>
      <li>bis 250 m³/h Volumenstrom: Wohnungslüftungsgeräte</li>
      <li>
        250 und 1.000 m³/h Volumenstrom: Lüftungsgeräte für Wohngebäude und
        Nichtwohngebäude.
      </li>
      <li>Über 1.000 m³/h Volumenstrom: Lüftungsgeräte für Nichtwohngebäude</li>
    </ul>
    <p>
      Im Folgenden sind die Anforderungen an Lüftungsgeräte für Nichtwohngebäude
      über 1.000 m³/h Volumenstrom zusammengestellt. Bei diesen Geräten
      differenziert die Verordnung im ersten Schritt nach
    </p>
    <ul>
      <li>einfachen Zuluft- oder Abluftgeräten,</li>
      <li>Geräten, die Zuluft und Abluft kombinieren.</li>
    </ul>

    <h4>Die Mindestanforderungen bei der Nicht-Wohnraum-Lüftung</h4>
    <p>
      Für einfache Zuluft- oder Abluftgeräte ist eine
      Mindest-Ventilatoreffizienz einzuhalten. Das heißt, die Ventilatoren im
      eingebauten Zustand müssen in Abhängigkeit der Leistungsaufnahme einen
      Mindestwirkungsgrad aufweisen, der entsprechend nach folgender Formel
      berechnet wird:
    </p>
    <ul>
      <li>6,2 % * ln(P) + 35 %, wenn P ≤ 30 kW und</li>
      <li>56,1 %, wenn P > 30 kW.</li>
    </ul>
    <img src="@/assets/help/fan/fan-power-eta.png" alt="Effizienzverlauf" />

    <p>
      Ist das einfache Zuluft- oder Abluftgerät mit einer Filterstufe versehen,
      wird zusätzlich der Strombedarf von Ventilator plus sauberem Filter im
      eingebauten Zustand begrenzt. Alle einfachen Zuluft- oder Abluftgeräte
      müssen ab 2016 eine elektrische Leistungsaufnahme von 250 Ws/m³ und ab
      2018 von 230 Ws/m³ (Siehe auch unten SFP-Klassen) unterschreiten.
    </p>
    <p>
      Sind Zuluft und Abluft in einem Gerät kombiniert, müssen die Ventilatoren
      ab 2016 entweder mit einer mehrstufigen oder stufenlosen Drehzahlregelung
      ausgerüstet werden. Ab 2018 ist darüber hinaus eine Filterüberwachung
      vorgeschrieben, die automatisch den Filtertausch mittels Warnsignal
      anmahnt.
    </p>
    <p>
      Bereits ab 2016 müssen alle kombinierten Zuluft- und Abluftgeräte mit mehr
      als 1.000 m³/h Volumenstrom über eine Wärmerückgewinnung verfügen. Für
      diese gelten folgende Mindestanforderungen an den trockenen Wirkungsgrad
      bei ausgeglichenem Volumenstrom (gem. EN 308 bei +5/+25 °C):
    </p>
    <p>
      Ab 2016 für Kreislaufverbundsysteme (KVS) 63 %, für alle anderen
      Wärmerückgewinnungssysteme (Plattenwärmeübertrager oder
      Rotationswärmeübertrager) 67 %. Ab 2018 für KVS 68 %, für alle anderen
      Wärmerückgewinnungssysteme 73 %.
    </p>

    <h3>SFP-Klassen</h3>
    <p>
      Klassifiziert werden Ventilatoren in Specific Fan Power-, kurz
      SFP-Klassen. Diese Klassen geben an, welche elektrische Leistung ein
      Ventilator pro Volumenstrom benötigt. (s.u.) Nach der aktuellen
      Energieeinsparverordnung (EnEV 2009) sind Neuanlagen mit mehr als 4000
      m³/h nur noch mit höchstens SFP-4 vorgesehen.
    </p>

    <p>
      $$SFP = P/V oder SFP= \delta p / \eta$$ mit \[P=V*\delta p/\eta\]
      \[\eta_System=\eta_FU*\eta_Motor*\eta_Antrieb*\eta_Ventilator \]
      \(\eta_System\) = Systemwirkungsgrad \(\eta_FU\) = Wirkungsgrad des
      Frequenzumrichters \(\eta_Motor\) = Wirkungsgrad des Antriebsmotors
      \(\eta_Antrieb\) = = Wirkungsgrad des Antriebs (Keilriemen 92%-97%,
      Flachriemen ca. 98%) \(\eta_Ventilator\) = Wirkungsgrad des Ventilators
    </p>

    <p>SFP-Klassen für Ventilatoren:</p>
    <table>
      <thead>
        <tr>
          <th>Kategorie</th>
          <th>spezifische Ven-tilatorleistung [Ws/m³]</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>SFP-1</td>
          <td>&lt;500</td>
        </tr>
        <tr>
          <td>SFP-2</td>
          <td>500&ndash;750</td>
        </tr>
        <tr>
          <td>SFP-3</td>
          <td>750&ndash;1250</td>
        </tr>
        <tr>
          <td>SFP-4</td>
          <td>1250&ndash;2000</td>
        </tr>
        <tr>
          <td>SFP-5</td>
          <td>2000&ndash;3000</td>
        </tr>
        <tr>
          <td>SFP-6</td>
          <td>3000&ndash;4500</td>
        </tr>
        <tr>
          <td>SFP-7</td>
          <td>&gt;4500</td>
        </tr>
      </tbody>
    </table>

    <h3>Optimierungsmöglichkeiten</h3>
    <p>
      Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten eines
      Ventilators:
    </p>
    <table>
      <thead>
        <tr>
          <th>Kategorie</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Motor</td>
          <td>Austausch des Ventilatorsystems inkl. Motor und Antrieb</td>
        </tr>
        <tr>
          <td>Dimensionierung</td>
          <td>Anpassen der Ventilatorgröße an den tatsächlichen Bedarf</td>
        </tr>
        <tr>
          <td>Regelung / Steuerung</td>
          <td>
            Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
            VSD)
          </td>
        </tr>
        <tr>
          <td>Betriebszeit</td>
          <td>
            Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
          </td>
        </tr>
        <tr>
          <td>Verbraucher</td>
          <td>
            Systemoptimierung zur Reduzierung des Bedarfs (Luftmenge, Druck)
          </td>
        </tr>
      </tbody>
    </table>

    <p>Mit der auführlichen Ventilatorberechnung (Ventilator 2) in EARNS lassen sich folgende Bestandssysteme betrachten:</p>
    <ul>
      <li>Ventilatoren mit vorwärtsgekrümmten Schaufeln (Trommelläufer)</li>
      <li>Ventilatoren mit rückwärtsgekrümmten Schaufeln (Radialläufer)</li>
    </ul>

    <h3>Bestands-Ventilator-Systeme</h3>
    <p>
      Bei den Bestandssystemen sind zwei typische Ventilatorsysteme hinterlegt:
    </p>

    <h4>Trommelläufer im Gehäuse</h4>
    <img src="@/assets/help/fan/trommel.png" alt="Trommelläufer" />

    <h4>Rückwärtsgekrümmter Ventilator im Gehäuse</h4>
    <img
      src="@/assets/help/fan/backwards.png"
      alt="Rückwärtsgekrümmter Ventilator"
    />

    <h3>Neusysteme</h3>
    <img src="@/assets/help/fan/new-systems.png" alt="Systemwirkungsgrade" />
    <p>
      Nachfolgend sind mehrere Neusysteme beschrieben, die derzeit die höchsten
      Systemwirkungsgrade erreichen. Diese wurden der EARNS-Kalkulation (Ventilator 1 & 2) zugrunde
      gelegt.
    </p>

    <h4>
      Direktangetriebener Radial-Ventilator mit EC-Motor (RadiPac, ebm-papst)
    </h4>
    <img src="@/assets/help/fan/direkt.png" alt="Direktantrieb" />
    <span>Einsatzbereich: 1.200 bis 32.000 m³/h ; bis 2.000 Pa</span>

    <p>
      Im RadiPac werden alle Bauteile eines herkömmlichen Ventilators vereint.
      Das Laufrad sitzt unmittelbar auf der Welle des Motors, und die
      Steuerungstechnik ist im Motorelement integriert. Durch die Kompaktheit
      des Ventilators kann er auch an Orten mit begrenztem Platz zum Einsatz
      kommen. Er muss nur noch eingebaut werden und ist danach betriebsbereit.
    </p>
    <p>
      Bedarf es eines höheren Volumenstroms, als ein Ventilator ihn erbringen
      kann, können mehrere RadiPac-Ventilatoren zu einer so genannten
      „Fan-Wall“ zusammen gebaut werden. Hierbei werden die Ventilatoren
      parallel, 2-dimensional angeordnet, um den Volumenstrom zu erhöhen.
    </p>

    <table>
      <tr>
        <td>
          <img src="@/assets/help/fan/fan-wall.png" alt="Ventilatorwand" />
        </td>
        <td>
          <img src="@/assets/help/fan/power-spec.png" alt="Ventilatorsystem" />
        </td>
      </tr>
      <tr>
        <td>
          <span>(Bild: RadiPac Fan-Wall, bestehend aus 3x3 Ventilatoren)</span>
        </td>
        <td>
          <span
            >(Bild: Leistungsbereiche verschiedener Baugrößen des RadiPac)
          </span>
        </td>
      </tr>
    </table>

    <h4>
      Direktangetriebener Radial-Ventilator mit EC-Motor, zweiseitig saugend
      (RadiFit, ebm-papst)
    </h4>
    <img src="@/assets/help/fan/direkt-double.png" alt="Zweiseitig saugend" />
    <span>Einsatzbereich: 1.500 bis 10.000 m³/h ; bis 1.500 Pa</span>
    <p>
      Der RadiFit-Ventilator integriert alle Komponenten eines konventionellen
      Ventilators. An einem innen liegenden, hocheffizienten EC-Motor ist das
      Radiallaufrad unmittelbar montiert, wodurch etwaige Antriebsverluste
      wegfallen. Die Steuerungseinheit des Motors ist an der Außenseite des
      Spiralgehäuses angebracht und ermöglicht zusammen mit dem Motor ein sehr
      gutes Teillastverhalten von 1:10 bei weiterhin hohen Wirkungsgraden.
    </p>
    <img src="@/assets/help/fan/kompakt.png" alt="Kompakt saugend" />
    <p>
      Durch die Kompaktheit des Ventilators verringern sich die Wartungs- und
      Instandhaltungskosten drastisch, wodurch sich ein Austausch eines
      Bestandsventilators bereits nach wenigen Jahren amortisiert und durch
      marktübliche Einbaumaße ist der Austausch in der Regel ohne Probleme
      durchzuführen.
    </p>
    <img
      src="@/assets/help/fan/performance-radfit.png"
      alt="Leistungsbereiche RadFit"
    />
    <span>(Bild: Leistungsbereiche verschiedener Baugrößen des RadiFit)</span>

    <h4>
      Direktangetriebener Radialventilator (RLM E6, Gebhardt) mit IE3-Motor und
      Frequenzumformer
    </h4>
    <img
      src="@/assets/help/fan/direkt-kompakt.png"
      alt="Leistungsbereiche RadFit"
    />
    <span>Einsatzbereich: 550 bis 60.000 m³/h ; bis 1.800 Pa</span>
    <p>
      Die Typbeschreibung xxx-E6 bedeutet, dass es sich um die Evo-Reihe von
      Nicotra-Gebhardt handelt, die sich durch besonders hohe Wirkungsgrade
      auszeichnet. Die Wirkungsgrade liegen je nach Ventilatorgröße bei 65 bis
      70%. Die Baureihe beinhaltet ein Komplett-Einbaumodul mit
      Motor-Laufrad-Einheit, Tragplatte mit Einströmdüse und Motorbock auf
      einem Grundrahmen.
    </p>

    <h4>
      1.6.4. Keilriemengetriebener Radialventilator (ADH, RDH, RZR Gebhardt)
      zweiseitig saugend mit IE3-Motor und Frequenzumformer
    </h4>
    <img
      src="@/assets/help/fan/keil-radial.png"
      alt="Keilriemengetriebener Radialventilator"
    />
    <span>Einsatzbereich: 600 bis 300.000 m³/h ; bis 3.500 Pa</span>
    <p>
      Riemengetriebene Radialventilatoren zeichnen sich weitgehend dadurch aus,
      dass sie sowohl hohe Drücke, als auch große Volumenströme ermöglichen
      können, wodurch sie sich besonders für den Betrieb in großen Anlagen
      eignen, in denen hohe Druckverluste vorliegen. Dabei ist ein Vorteil, dass
      die Ventilatoreinheit auf Grund der Trennung von Antrieb und Ventilator
      durch einen Riementrieb leicht an veränderte Betriebsbedingungen angepasst
      werden kann, zum Beispiel indem der Motor getauscht wird. Durch die
      Dezentralität entstehen aber erhöhte Wartungs- und Verschleißkosten,
      ebenso wie zusätzliche Verluste durch den Riementrieb, wodurch der
      Wirkungsgrad verringert wird. Im Allgemeinen steigt der Wirkungsgrad mit
      der Größe des Ventilators an.
    </p>

    <h4>Wirkungsgrad Neusystem</h4>
    <p>EARNS rechnet mit dem Gesamtwirkungsgrad des Ventilator-Motor-Antriebs-Neusystems. Hierfür werden die besten Wirkungsgrade der o.g. Ventilatorsysteme im jeweiligen Anwendungsbereich verwendet.</p>
    <p>Die Prüfung, welches der o.g. Neusysteme für den jeweiligen Anwendungsfall, Betriebspunkt und Einbausituation das günstigste ist, ist vom Projektbearbeiter im Rahmen der Feinanalyse vorzunehmen.</p>
<img
      src="@/assets/help/fan/eta-new-system.png"
      alt="Wirkungsgrade Gesamtsystem"
    />
    <p>Betrachtet man das Effizienzsteigerungspotential, so haben die Maßnahmen in etwa folgende Anteile:</p>
    <ul>
      <li>Einsatz eines Ventilator-Neusystems (Verbesserung Gesamtwirkungsgrad)  25% </li>
      <li>Elektronische Drehzahlregelung (VSD/FU) 35% </li>
      <li>Bedarfsreduzierung 40% </li>
    </ul>

    <h2>Hinweise zur Durchführung</h2>
    <p>
      Die Methode 1 ist auf die Durchführung von Potentialanalysen
      zugeschnitten. Ziel ist es, mit geringem Aufwand für Begehung und
      Datenaufnahme einen Überblick über die vorhandenen
      Energieeinsparpotentiale einer Liegenschaft zu erhalten. In der Auswertung
      der Potentialanalyse werden die Energieeinsparpotentiale mit einem
      Toleranzbereich von +- 15% angegeben.
    </p>
    <p>
      Werden vom Kunden bereits im Rahmen der Potentialanalyse detailliertere
      Angaben geliefert, so kann auch Methode 2 für die Durchführung der
      Potentialanalyse verwendet werden.
    </p>

    <parameter-help method="fan1" />

    <h2>Quellen</h2>
    <ul>
      <li>Nicotra Gebhardt GmbH</li>
      <li>ebmpapst Gruppe</li>
      <li>AL-KO</li>
      <li>
        ILK Dresden Fachbericht ILK-B-31-13-3839, Auftraggeber: Danfoss GmbH
      </li>
    </ul>
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "Fan2Help",
  components: {
    ParameterHelp,
  },
  mounted() {
    if (window.MathJax) {
      window.MathJax.typeset()
    }
  }
};
</script>

<style scoped>
</style>