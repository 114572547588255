import httpAdapter from 'axios/lib/adapters/http'
import LRU from 'lru-cache'

const cache = new LRU({
    max: 20000,  // about 20kB of cache
    maxAge: 60*60*1000,
    length: (data) => {
        if (data instanceof Promise) {
            return 1
        }
        return JSON.stringify(data.data).length
    }
})


// This adapter caches the responses of successful get requests.
// By first caching the initial promise from the httpAdapter, it is immune to race conditions
// !!!!!!!  IMPORTANT: never ever modify responses got using this adapter as this might also modify the cached value  !!!!!!!
const cacheAdapter = function(config) {
    let key = JSON.stringify({url: config.url, params: config.params || {}})

    let promise;
    let cached = cache.get(key);
    if (cached) {
        if (cached instanceof Promise) {
            promise = cached
        } else {
            promise = new Promise((resolve) => {
                resolve(cached)
            })
        }
    } else {
        promise = new Promise((resolve, reject) => {
            httpAdapter(config).then(response => {
                cache.set(key, response)
                resolve(response)
            }).catch(error => {
                cache.del(key)
                reject(error)
            })
        })
        cache.set(key, promise)
    }
    return promise
}


export {cacheAdapter}