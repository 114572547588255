<template>
  <div>
    <h1>Nassläuferpumpe 1</h1>

    <img src="@/assets/help/wetpump/wetpump.png" alt="Nassläuferpumpe" />
    <figcaption>Quelle: Wilo</figcaption>
    <p>
      Elektromotoren sind in Europa die wichtigsten Stromverbraucher: Sie sind
      für rund 70 % des industriellen Stromverbrauchs und ein Drittel des
      Stromverbrauchs im Dienstleistungssektor verantwortlich. Fast 20 % des
      weltweiten Stromverbrauchs von Motoren entfallen dabei auf Pumpen. Eine
      Reduzierung des Energieverbrauchs kommt nicht nur der Umwelt – durch
      Ressourcenschonung und weniger klimaschädliches CO2 – zugute, sondern
      auch der Nachhaltigkeitsbilanz der Unternehmen. Denn Stromkosten machen
      den größten Teil der Lebenszykluskosten einer Pumpe aus – bis zu 85 %.
      Das weltweite Einsparpotenzial allein durch die Anwendung moderner
      Pumpentechnik beläuft sich auf bis zu 246 TWh, das entspricht einer
      Größenordnung von 24 Atomkraftwerken.
    </p>

    <h3>Optimierungsmöglichkeiten</h3>
    <p>
      Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten eines
      elektrischen Antriebssystems:
    </p>
    <table>
      <thead>
        <tr>
          <th>Kategorie</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Motor</td>
          <td>Austausch der Pumpe durch neue energieeffiziente Pumpe</td>
        </tr>
        <tr>
          <td>Dimensionierung</td>
          <td>Anpassen der Pumpengröße an den tatsächlichen Bedarf</td>
        </tr>
        <tr>
          <td>Regelung / Steuerung</td>
          <td>
            Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
            VSD)
          </td>
        </tr>
        <tr>
          <td>Betriebszeit</td>
          <td>
            Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
          </td>
        </tr>
        <tr>
          <td>Verbraucher</td>
          <td>Systemoptimierung zur Reduzierung des Bedarfs</td>
        </tr>
      </tbody>
    </table>

    <h3>Energieeffizienzklassen für Pumpen</h3>
    <table>
      <thead>
        <tr>
          <th>Klasse</th>
          <th>Energie Effizienz Index (EEI)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>A</td>
          <td>EEI &lt; 0,4</td>
        </tr>
        <tr>
          <td>B</td>
          <td>0,4 &leq; EEI &lt; 0,6</td>
        </tr>
        <tr>
          <td>C</td>
          <td>0,6 &leq; EEI &lt; 0,8</td>
        </tr>
        <tr>
          <td>D</td>
          <td>0,8 &leq; EEI &lt; 1,0</td>
        </tr>
        <tr>
          <td>E</td>
          <td>1,0 &leq; EEI &lt; 1,2</td>
        </tr>
        <tr>
          <td>F</td>
          <td>1,2 &leq; EEI &lt; 1,4</td>
        </tr>
        <tr>
          <td>G</td>
          <td>1,4 &leq; EEI</td>
        </tr>
      </tbody>
    </table>
    <p>
      Der Energieeffizienzindex (EEI) einer Pumpe ist das Verhältnis der
      gewichteten, mittleren Leistungsaufnahme (ermittelt in einem
      standardisierten Lastprofil) zur Bezugsleistung. Die Bezugsleistung
      bezieht sich auf das Verhältnis zwischen der hydraulischen und der
      elektrischen Leistung der Pumpe unter Berücksichtigung der
      Größenabhängigkeit. Zusätzlich wird bei der Berechnung des EEI ein Faktor
      eingerechnet, der sicherstellen soll, dass höchstens 20 Prozent der am
      Markt verfügbaren Produkte einen EEI von 0,20 erreichen.
    </p>
    <p>
      Nach ErP-Richtlinie für Nassläufer-Umwälzpumpen (Verordnung (EG) 641/2009
      und (EU) 622/2012) müssen alle neuen Pumpen ab 2013 einen
      mindest-Energieeffizienzindex erfüllen, um auf dem Markt gebracht werden
      zu dürfen. 2015 und 2017 treten weitere Verschärfungen an die
      Anforderungen an Nassläuferpumpen in Kraft. (s. Abb. 2)
    </p>
    <img
      src="@/assets/help/wetpump/anforderungen.png"
      alt="Anforderungen an EEI von Nassläuferpumpen"
    />

    <h3>Neue Pumpentechnologie</h3>
    <span><b>Wilo-Stratos / Wilo-Stratos PICO</b></span>
    <p>
      Die Wilo-Stratos ist eine Baureihe für Hocheffizienzpumpen des Herstellers
      Wilo. Angetrieben werden die Pumpen durch einen hocheffizienten EC-Motor.
      EC-Motoren haben gegenüber konventionellen AC-Motoren den Vorteil, dass
      sich im Rotor des Motors keine Spulen, sondern ein starker Dauermagnet
      befindet, der bereits ohne das Aufwenden von Energie das benötigte
      magnetische Feld aufbaut. Benötigt wird aber zusätzlich eine
      VSD-Drehzahlregelung, die i.d.R. im Motor integriert wird. Insgesamt
      ergeben sich für EC-Motoren wesentlich bessere Wirkungsgrade als für
      AC-Motoren, vor allem im Teillastbetrieb. Mit einem EEI ≤0,23 wird somit
      die Wilo-Stratos Baureihe auch den zukünftigen Anforderungen an die
      Energieeffizienz gerecht. Die Leistungsaufnahme liegt je nach Bedarfsfall
      zwischen 9W und 1150W mit einer maximalen Förderhöhe von 13m und einem
      maximalen Förderstrom von 62m³/h.
    </p>
    <p>
      Über eine Kommunikationsschnittstelle für alle gängigen Protokolle
      (Modbus, BACnet, CAN, PLR, LON) können die Pumpen direkt an die
      Gebäudeautomation angebunden werden. Für kleine Lasten ist die
      Wilo-Stratos-PICO noch effizienter mit einem EEI≤0,2 und einer
      Leistungsaufnahme von 3W bis 40W bei einer maximalen Förderhöhe von 6m
      und einem maximalen Förderstrom von 3,5m³/h.
    </p>
    <span><b>Andere Pumpenfabrikate</b></span>
    <p>
      Auch andere große Pumpenhersteller bieten Nassläuferpumpen mit
      EC-Motorentechnologie an, wie zum Beispiel Grundfos Baureihe Magna, KSB
      mit der Rio-Eco oder DAB mit der Baureihe EVOPLUS.
    </p>
    <p>
      Die EARNS-Berechnungen verwenden exemplarisch die Angaben für die
      Wilo-Stratos.
    </p>
    <img src="@/assets/help/wetpump/wilo-stratos.png" alt="Wilo Stratos" />
    <figcaption>Quelle: Wilo</figcaption>


    <h2>Hinweise zur Durchführung</h2>
    <p>
      Die Methode 1 ist auf die Durchführung von Potentialanalysen
      zugeschnitten. Ziel ist es, mit geringem Aufwand für Begehung und
      Datenaufnahme einen Überblick über die vorhandenen
      Energieeinsparpotentiale einer Liegenschaft zu erhalten. In der Auswertung
      der Potentialanalyse werden die Energieeinsparpotentiale mit einem
      Toleranzbereich von +- 15% angegeben.
    </p>
    <p>
      Werden vom Kunden bereits im Rahmen der Potentialanalyse detailliertere
      Angaben geliefert, so kann auch Methode 2 für die Durchführung der
      Potentialanalyse verwendet werden.
    </p>

    <parameter-help method="wetpump1" />
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "WetPump1Help",
  components: {
    ParameterHelp,
  },
};
</script>

<style scoped>
</style>