import "@/assets/style.scss"

import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import "./plugins/axios"
import "./filters"
import router from "./router"
import store from "./store"

Vue.config.productionTip = false

const app = new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app")

store.subscribe( (mutation, state) => {
  localStorage.setItem("noCalc", JSON.stringify(state.noCalc));
  localStorage.setItem("noCalcGroup", JSON.stringify(state.noCalcGroup));
})

export default app