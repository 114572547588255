<template>
  <div>
    <h1>RLT</h1>

    <img src="@/assets/help/hvac/hvac.png" alt="RLT-Anlage">
    <p>DIN 18599-3 erlaubt die Endenergieberechnung von Lüftungsanlagen. Es wird dabei unterschieden zwischen Anlagen, die mit einem konstanten Volumenstrom betrieben werden und Anlagen welche einheitlich über das Jahr verteilt, gleichmäßig geregelt werden (Absenkbetrieb). Die Energie für die Lüftungsanlagen setzt sich aus der elektrischen Energie für die Luftförderung und der Energie für die Luftkonditionierung zusammen. Die Berechnung der jeweiligen Energien erfolgt auf Grundlage von Energiekennwerten für die Monate Januar bis Dezember.</p>
    <p>Der Energiebedarf für die Luftkonditionierung hängt des Weiteren von folgenden Faktoren ab:</p>
    <ul>
        <li>Feuchteanforderung</li>
        <li>Typ des Luftbefeuchtungssystem</li>
        <li>Typ des Wärmerückgewinnungssystem</li>
        <li>Dimensionierung des Wärmerückgewinnungssystem</li>
        <li>Volumenstrom</li>
    </ul>

    <h3>Methodik</h3>
    <p>Die Leistungsaufnahme wird für die Betriebspunkte Volllast und Absenkbetrieb berechnet.</p>

    <h2>Parameter</h2>

    <h3>Ventilatoren separat berechnen</h3>
    <p>Die Berechnung der elektrischen Arbeit durch das Ventilatorsystem
        kann über die Methode Ventilator detailiert erfolgen. Dazu sollte das
        Ventilatorsystem hier nicht berechnet werden, um die elektrische
        Arbeit nicht doppelt zu berücksichtigen.</p>

    <h3>Zuluft Volumenstrom</h3>
    <p>Mittlerer Volumenstrom im Nennbetrieb.</p>

    <h3>Zuluft Absenkvolumenstrom</h3>
    <p>Mittlerer Volumenstrom im Asenkbetrieb.</p>

    <h3>Zuluft Druckverlust</h3>
    <p>Gesamter Druckverlust in Nennbetrieb des Zuluftstrangs.</p>

    <h3>Zuluft Systemwirkungsgrad</h3>
    <p>Systemwirkungsgrad zur Luftförderung besetehend aus Ventilator, Kraftübertrageng (z.B. Keilriemen), Motor und ggf. FU.</p>

    <h3>Abluft Volumenstrom</h3>
    <p>Mittlerer Volumenstrom im Nennbetrieb.</p>

    <h3>Abluft Druckverlust</h3>
    <p>Gesamter Druckverlust in Nennbetrieb des Abluftstrangs.</p>

    <h3>Abluft Systemwirkungsgrad</h3>
    <p>Systemwirkungsgrad zur Luftförderung besetehend aus Ventilator, Kraftübertrageng (z.B. Keilriemen), Motor und ggf. FU.</p>

    <h3>Tägliche Betriebsstunden</h3>
    <p>Mittlere tägliche Betriebsstunden der RLT-Anlage. Zur Mittelung nur Tage berücksichtigen, an denen die Anlage betrieben wird.</p>

    <h3>Betriebstage im Jahr</h3>
    <p>Anzahl der Tage im Jahr, an denen die RLT-Anlage betrieben wird (Entweder Tage im Jahr oder Tage im Monat angeben).</p>

    <h3>Betriebstage im Monat</h3>
    <p>Anzahl der Tage im Monat, an denen die RLT-Anlage betrieben wird (Entweder Tage im Jahr oder Tage im Monat angeben).</p>

    <h3>Absenkbetrieb</h3>
    <p>Werden im normalen Anlagenbetrieb die Volumenströme regelmäßig über das Jahr verteilt reduziert?</p>
    
    <h3>Tägliche Betriebsstunden im Absenkbetrieb</h3>
    <p>Mittlere tägliche Betriebsstunden der RLT-Anlage im Absenkbetrieb.</p>

    <h3>Zulufttemperatur</h3>
    <p>Mittlere Zulufttemperatur.</p>

    <h3>Feuchteanforderung</h3>
    <p>Angaben zur Regelung der Feuchteanforderung.</p>

    <h3>Befeuchter</h3>
    <p>Art des Befeuchtersystems.</p>

    <h3>Wärmerückgewinnung</h3>
    <p>Art der Wärmerückgewinnung.</p>

    <h3>Wirkungsgrad Wärmerückgewinnung</h3>
    <p>Wirkungsgrad der Wärmerückgewinnung.</p>

  </div>
</template>

<script>

export default {
  name: "HVACHelp"
}
</script>

<style scoped>

</style>