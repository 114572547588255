<template>
  <div>
    <h1>Elektrische Antriebe 1</h1>
    <img
      src="@/assets/help/electricdrive/electricdrive.png"
      alt="Elektromotor"
    />
    <p>
      Elektromotoren sind in Europa die wichtigsten Stromverbraucher: Sie sind
      für rund 70 % des industriellen Stromverbrauchs und ein Drittel des
      Stromverbrauchs im Dienstleistungssektor verantwortlich. Die Kosten für
      den Stromverbrauch machen bis zu 96 % der Kosten eines Motors über die
      gesamte Lebensdauer aus. Der Rest teilt sich zu 2,5 % auf den Kaufpreis
      und zu 1,5 % auf die Wartungskosten auf.
    </p>

    <h3>Optimierungsmöglichkeiten</h3>
    <p>
      Folgende Maßnahmen haben wesentlichen Einfluss auf die Energiekosten eines
      elektrischen Antriebssystems:
    </p>
    <table>
      <thead>
        <tr>
          <th>Kategorie</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Motor</td>
          <td>Austausch des Motors durch neuen energieeffizienten Motor IE3</td>
        </tr>
        <tr>
          <td>Dimensionierung</td>
          <td>Anpassen der Motorgröße an den tatsächlichen Bedarf</td>
        </tr>
        <tr>
          <td>Regelung / Steuerung</td>
          <td>
            Einsatz einer stufenlosen Drehzahlregelung (Variable Speed Drive
            VSD)
          </td>
        </tr>
        <tr>
          <td>Betriebszeit</td>
          <td>
            Anpassung der Betriebszeit an die tatsächlichen Nutzungszeiten
          </td>
        </tr>
        <tr>
          <td>Verbraucher</td>
          <td>Mechanische Systemoptimierung zur Reduzierung des Bedarfs</td>
        </tr>
      </tbody>
    </table>

    <h3>Motor - Wirkungsgradklassen EFF und IE</h3>
    <h4>Bisherige Wirkungsgradklassen (EFF)</h4>
    <p>
      Im Rahmen der freiwilligen Selbstverpflichtung zwischen dem europäischen
      Sektor-Komitee für elektrische Antriebe CEMEP und der Europäischen
      Kommission wurden im Jahr 1998 drei Wirkungsgradklassen im
      Leistungsbereich 1,1 - 90 kW definiert:
    </p>

    <table>
      <thead>
        <tr>
          <th>Klasse</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>EFF3</td>
          <td>
            Motoren mit niedrigem Wirkungsgrad (meist eingesetzt bis ca. Jahr
            2000)
          </td>
        </tr>
        <tr>
          <td>EFF2</td>
          <td>Motoren mit verbessertem Wirkungsgrad</td>
        </tr>
        <tr>
          <td>EFF1</td>
          <td>Motoren mit erhöhtem Wirkungsgrad</td>
        </tr>
      </tbody>
    </table>
    <img
      src="@/assets/help/electricdrive/marktanteile-eff.png"
      alt="Marktanteile von Eff-Motoren"
    />
    <h4>Neue Wirkungsgradklassen (IE) ab 2008</h4>
    <p>
      Die Norm IEC 60034-30:2008 definiert die Wirkungsgradklassen für
      Drehstrom-Niederspannungsmotoren im Leistungsbereich von 0,75 kW bis 375
      kW. Die Abkürzung IE steht für International Efficiency
    </p>
    <table>
      <thead>
        <tr>
          <th>Klasse</th>
          <th>Beschreibung</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>IE1</td>
          <td>Standard Wirkungsgrad (vergleichbar EFF2)</td>
        </tr>
        <tr>
          <td>IE2</td>
          <td>Hoher Wirkungsgrad (vergleichbar EFF1)</td>
        </tr>
        <tr>
          <td>IE3</td>
          <td>Premium Wirkungsgrad</td>
        </tr>
        <tr>
          <td>IE4</td>
          <td>Super Premium Efficiency</td>
        </tr>
      </tbody>
    </table>

    <p>Fristen:</p>
    <ul>
      <li>
        Seit Juli 2021 müssen Dreiphasenmotoren mit einer Nennausgangsleistung
        zwischen 0,75 kW und höchstens 1000 kW das Effizienzniveau IE3
        aufweisen.
      </li>
      <li>
        Ab Juli 2023 ist für Motoren mit einer Leistung zwischen 75 kW und 200
        kW das Effizienzniveau IE4 verpflichtend.
      </li>
    </ul>

    <img
      src="@/assets/help/electricdrive/power-eff.png"
      alt="Effizienz über Nennleitung"
    />

    <h4>Vergleich mit den bisherigen Wirkungsgradklassen (EFF-Klassen)</h4>
    <p>
      Der Hauptunterschied zwischen den beiden Wirkungsgradklassen (EFF und IE)
      liegt im Verfahren zu deren Ermittlung. Beim direkten Vergleich am selben
      Motor ist davon auszugehen, dass sich der nach den neuen Messverfahren
      ermittelte Wirkungsgrad reduziert. So ist z. B. ein 4-poliger, 11 kW, EFF1
      Motor mit einem Wirkungsgrad von 91,0 % physikalisch identisch mit einem
      IE2 Motor mit einem Wirkungsgrad von 89,8 %.
    </p>
    <img
      src="@/assets/help/electricdrive/eff-klassen.png"
      alt="Effizienz Klassen"
    />

    <h2>Hinweise zur Durchführung</h2>
    <p>
      Die Methode 1 ist auf die Durchführung von Potentialanalysen
      zugeschnitten. Ziel ist es, mit geringem Aufwand für Begehung und
      Datenaufnahme einen Überblick über die vorhandenen
      Energieeinsparpotentiale einer Liegenschaft zu erhalten. In der Auswertung
      der Potentialanalyse werden die Energieeinsparpotentiale mit einem
      Toleranzbereich von +- 15% angegeben.
    </p>
    <p>
      Werden vom Kunden bereits im Rahmen der Potentialanalyse detailliertere
      Angaben geliefert, so kann auch Methode 2 für die Durchführung der
      Potentialanalyse verwendet werden.
    </p>

    <parameter-help method="electricdrive1" />
  </div>
</template>

<script>
import ParameterHelp from "@/components/help/ParameterHelp";

export default {
  name: "ElectricDrive1Help",
  components: {
    ParameterHelp,
  },
};
</script>

<style scoped>
</style>