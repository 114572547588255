<template>
  <v-card height="400px" class="mt-5 mb-5" >
    <div v-if="mapLat" id="map" style="width: 100%; height: 100%; rounded:"></div>
  </v-card>
</template>

<script>
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { useGeographic } from "ol/proj";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";

export default {
  name: "MapContainer",
  components: {},
  props: {
    mapLat: null,
    mapLon: null
  },

  data() {
    return {
      mainMap: null,
      initialCoordinates: [this.mapLon, this.mapLat]
    };
  },

  mounted() {
    this.myMap();
  },

  methods: {
    myMap() {
      useGeographic();
      this.mainMap = new Map({
        layers: [
          new TileLayer({
            source: new OSM()
          }),
          new VectorLayer({
            title: "Earns Lokation",
            source: new VectorSource({
              features: [
                new Feature({
                  geometry: new Point(this.initialCoordinates)
                })
              ]
            }),
            style: new Style({
              image: new Icon({
                src: "http://maps.google.com/mapfiles/kml/paddle/blu-circle.png",
                scale: 0.5,
                anchor: [0.5, 1]
              })
            })
          })
        ],
        target: "map",
        view: new View({
          center: this.initialCoordinates,
          zoom: 14
        })
      });
      setTimeout(() => {
        this.mainMap.updateSize();
      }, 500);
    }
  }
};
</script>

<style>
#map
{     
    border-radius: 28px!important;
    overflow: hidden;
}
</style>
