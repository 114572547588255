import { render, staticRenderFns } from "./WetPump1Help.vue?vue&type=template&id=bedfcaa8&scoped=true&"
import script from "./WetPump1Help.vue?vue&type=script&lang=js&"
export * from "./WetPump1Help.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bedfcaa8",
  null
  
)

export default component.exports