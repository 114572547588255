<template>
  <v-form @submit.prevent="" ref="form">
    <v-text-field
      v-model.number="amount"
      type="number"
      :error-messages="fieldErrors.amount"
      label="Anzahl"
      :hint="hint.amount"
      :rules="validationErrors.amount"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.amount
        "
        @click="getReferenceParameter(`amount`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-model="nominalWidth"
      :error-messages="fieldErrors.nominalWidth"
      label="Nennweite"
      suffix="mm"
      :items="widths"
      item-text="width"
      item-value="width"
      :rules="validationErrors.nominal_width"
      validate-on-blur
    >
      <v-tooltip v-if="hint.nominal_width" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.nominal_width }}</span>
      </v-tooltip>
      <v-btn
        v-if="
          !reference &&
          this.referenceData.nominal_width
        "
        @click="getReferenceParameter(`nominal_width`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-checkbox
      v-model="checkNormUptime"
      :error-messages="fieldErrors.checkNormUptime"
      label="Norm-Betriebsstunden"
    >
      <v-tooltip v-if="hint.check_norm_uptime" slot="append" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.check_norm_uptime }}</span>
      </v-tooltip>
    </v-checkbox>

    <v-autocomplete
      v-model="normUptimeObject"
      label="Norm-Betriebsstunden"
      :items="uptimes"
      item-text="name"
      item-value="id"
      return-object
      v-if="!!checkNormUptime"
      :rules="validationErrors.norm_uptime"
      validate-on-blur
    >
      <v-tooltip v-if="hint.norm_uptime" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.norm_uptime }}</span>
      </v-tooltip>
      <v-btn
        v-if="
          !reference &&
          this.referenceData.norm_uptime
        "
        @click="getReferenceParameter(`norm_uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-autocomplete>

    <v-text-field
      v-model.number="uptime"
      type="number"
      :error-messages="fieldErrors.uptime"
      label="Betriebsstunden"
      suffix="h/a"
      :disabled="!!checkNormUptime"
      :hint="hint.uptime"
      :rules="validationErrors.uptime"
      validate-on-blur
    >
      <v-btn
        v-if="
          !reference &&
          this.referenceData.uptime
        "
        @click="getReferenceParameter(`uptime`)"
        slot="append-outer"
        title="Referenzwert übernehmen"
        icon
      >
        <v-icon>mdi-content-copy</v-icon>
      </v-btn>
    </v-text-field>

    <v-autocomplete
      v-if="reference"
      v-model="regulation"
      :error-messages="fieldErrors.regulation"
      label="Regelung"
      :items="regulations"
      :rules="validationErrors.regulation"
      validate-on-blur
    >
      <v-tooltip v-if="hint.regulation" slot="append-outer" bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">mdi-information</v-icon>
        </template>
        <span>{{ hint.regulation }}</span>
      </v-tooltip>
    </v-autocomplete>

  </v-form>
</template>

<script>
import BaseForm from "@/components/methods/BaseForm";
import { djangoChoicesToVuetify } from "@/utils";
import { cacheAdapter } from "@/cache";

export default {
  name: "WetPump1Form",
  extends: BaseForm,

  data() {
    return {
      amount: null,
      uptime: null,
      nominalWidth: null,
      regulation: null,
      checkNormUptime: false,

      fields: {
        amount: "amount",
        uptime: "uptime",
        nominalWidth: "nominal_width",
        regulation: "regulation",
        checkNormUptime: "check_norm_uptime"
      },
      normUptimeObject: null,

      regulations: [],
      uptimes: [],
      widths: []
    };
  },
  computed: {
    normUptime() {
      return this.normUptimeObject?.norm_uptime;
    }
  },
  watch: {
    normUptime(value) {
      if (!!value) {
        this.uptime = value;
      }
    }
  },
  beforeMount() {
    this.fetchNormUptimes();
    this.fetchRegulations();
    this.fetchWidths();
  },
  mounted() {
    if (!this.checkNormUptime) {
      this.checkNormUptime = false;
    }
  },
  methods: {
    fetchNormUptimes() {
      this.$http
        .get("/api/v1/methods/wetpump1/uptime/", { adapter: cacheAdapter })
        .then((response) => {
          this.uptimes = response.data;
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    fetchRegulations() {
      this.$http
        .get("/api/v1/methods/wetpump1/regulation/", {
          params: {
            planned: this.planned || null,
            reference: this.reference || null
          },
          adapter: cacheAdapter
        })
        .then((response) => {
          this.regulations = djangoChoicesToVuetify(response.data);
        })
        .catch(() => {
          // TODO: Error handling
        });
    },

    fetchWidths() {
      this.$http
        .get("/api/v1/methods/wetpump1/nominal-width/", {
          adapter: cacheAdapter
        })
        .then((response) => {
          this.widths = response.data;
        })
        .catch(() => {
          // TODO: Error handling
        });
    }
  }
};
</script>

<style scoped></style>
